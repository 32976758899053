.makerecv{
    background-color:#558B2F;
    padding:1px 5px;
    border-color:#3C6F18;
    border-radius:3px;
    color:#fff;
    font-size:12px;
    margin-right: 5px;
    /* line-height: 1.5; */
    font-weight: 400;
    touch-action: manipulation;
}
.makerecv:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}




.modal {
    position: fixed;
    top: 64px;
    padding: 24px;
    left: 64px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 3px;
  }