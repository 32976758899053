@font-face {
    font-family: 'WebFonts';
    src: url(../../../../../assets/fonts/AlegreyaSans-Medium.woff);
  }



.viewordertemp {
    position: relative;
    /* background: #6a1414;
    border: 0px solid #6a1414; */
    padding-top: 0px;
    padding-bottom: 0px;
    /* width: 594px;
    height: 792px; */

    /* width: 618px;
    height: 816px; */

    /* width: 620.26px;
    height: 818.26px; */

    width: 646.26px;
    height: 844.26px;
    

}

.size {
    font-size: 14.45px;
}

.icon {
    width: 80px;
    float: none;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 30px;
}

.icon1 {
    width: 80px;
    float: none;
    cursor: pointer;
    margin-left: 10px;
    margin-top: -180px;
}

.add {
    margin-left: 50px;
}

.add1 {
    border: solid 2px #e3e3e3;
    color: #555;
    height: 40px;
    background: none;
    border-radius: 2px;
    box-shadow: none;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
    padding: 8px;
}

.up1 {
    margin-left: 10px;
    margin-top: 15px;
    margin-right: 40px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
}

.para {
    margin-right: 8px;
    word-break: break-all;
    font-size: 13.5px;
    margin-bottom: 15px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    cursor: pointer;
}

.tem1 {
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    left: 43px;
}

.tem2 {
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    right: 326px;
}

.tem3 {
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    left: 328px;
}

.tem4 {
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    left: 470px;
}

.tem6 {
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 398px;
    bottom: 316px;
}

.tem7 {
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 398px;
    top: 536px;
}

.tem8 {
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 398px;
    top: 674px;
}

.tem5 {
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 398px;
    top: 260px;
}

.iqama {
    width: 320px;
    height: 454px;
    top: 300px;
    left: 41px;
    position: absolute;
}

.time {
    width: 346px;
    position: absolute;
    top: 260px;
    left: 41px;
    line-height: 0px;
}

.style {
    display: block;
    background: none;
    color: #D21A1A;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.just {
    margin-top: -1px;
}

.bk {
    color: white;
    width: 30px;
    margin-left: 10px;
    margin-bottom: 20px;
    background: grey;
    height: 40px;
    font-size: 14px;
    border-radius: 2px;
    font-weight: "bolder",
}

.bk:hover {
    background-color: #f05a5a;
}

.h2 {
    margin: 50px 0 30px 0;
    font-size: 30px;
    display: block;
}

.ol {
    margin: 0 0 50px 20px;
    font: 17px Roboto, Arial, sans-serif;
    color: black;
}

.fil {
    height: 5px;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

.inst {
    font-size: 18px;
    font-family: Roboto, Arial, sans-serif;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 13px;
    display: block;
}

.are {
    border: solid 2px #e3e3e3;
    color: #777;
    height: 150px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
    padding: 8px;
    width: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-height: 40px;
}

.dp {
    position: relative;
    background-color: #f7f7f7;
    padding: 10px 10px 15px 10px;
    width: 500px;
    height: 50px;
    border-radius: 3px;
    align-items: center;
    border: solid 1px #e1e1e1;
    background-color: transparent;
    margin: 1px;
    -webkit-box-align: center;
    display: flex;
}

.fil1 {
    height: 40px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    font-weight: normal;
    width: auto;
    border-color: #e1e1e1;
    border-style: solid;
    border-width: 0;
    zoom: 1;
}

.but {
    color: white;
    width: 30px;
    margin-left: 410px;
    margin-bottom: 20px;
    background-color: #D21A1A;
    padding: 10px 80px 10px 15px;
    font-size: 14px;
    border-radius: 2px;
    margin-top: 20px;
}

.but:hover {
    background-color: #dd4b4b;
}

.up2 {
    margin-top: 15px;
    margin-left: -30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
}

.radios1 input[type=radio]:checked+img {
    outline: 68px solid rgba(210, 26, 26, 0.6);
    outline-offset: -68px;
    overflow: hidden;
    position: absolute;
}

.radios1 input[type=radio] {
    display: none;
}

input[disabled] {
    cursor: not-allowed;
}

input[type="radio"][disabled] {
    background-color: transparent;
}

input[type="radio"] {
    width: auto;
    line-height: normal;
}

[type=radio] {
    position: absolute !important;
    opacity: 0 !important;
    height: 0 !important;
}

/* .iqama-table tr th:first-child,
.iqama-table tr td:first-child {
    background-color: #b2d0da;
}

.iqama-table tr th:nth-child(3),
.iqama-table tr td:nth-child(3) {
    background-color: #b2d0da;
}

.iqama-table tr th:nth-child(4),
.iqama-table tr td:nth-child(4) {
    background-color: #b2d0da;
    min-width: 80px !important;
    width: 80px;
}

.iqama-table tr th:nth-child(5),
.iqama-table tr td:nth-child(5) {
    background-color: #b2d0da;
}

.iqama-table tr th:nth-child(7),
.iqama-table tr td:nth-child(7) {
    background-color: #b2d0da;
}

.iqama-table tr th:nth-child(2),
.iqama-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqama-table tr th:nth-child(6),
.iqama-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}


/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */

.head-size {
    font-size: 6px;
}

.iqama-table th,
.iqama-table td {
    line-height: 0px;
    border: 1px solid #dddddd;
    padding: 7.9px;
    font-family: Arial;
    font-weight: 700;
}

.iqama1{
    width: 346px;
    height: 478px;
    top: 80px;
    left: 15px;
    position: absolute;
}

.time1{
    width: 346px;
    height: 52px;
    position: absolute;
    top: 43px;
    left: 41px;
}

.temph1{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    right: 42px;
}

.temph2{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    top: 180px;
    right: 42px;
}

.temph3{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    top: 317px;
    right: 42px;
}

.temph4{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    top: 453px;
    right: 42px;
}

.temph5{
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 594px;
    left: 470px;
}

.temph6{
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 594px;
    left: 328px;
}

.temph7{
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 326px;
}

.temph8{
    width: 137px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 594px;
    left: 40px;
}

.iqama1-table tr th:first-child,
.iqama1-table tr td:first-child {
    background-color: #b2d0da;
}

.iqama1-table tr th:nth-child(3),
.iqama1-table tr td:nth-child(3) {
    background-color: #b2d0da;
}

.iqama1-table tr th:nth-child(4),
.iqama1-table tr td:nth-child(4) {
    background-color: #b2d0da;
    min-width: 80px !important;
    width: 80px;
}

.iqama1-table tr th:nth-child(5),
.iqama1-table tr td:nth-child(5) {
    background-color: #b2d0da;
}

.iqama1-table tr th:nth-child(7),
.iqama1-table tr td:nth-child(7) {
    background-color: #b2d0da;
}

.iqama1-table tr th:nth-child(2),
.iqama1-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqama1-table tr th:nth-child(6),
.iqama1-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
}

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqama1-table th,
.iqama1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    font-family: Arial;
    font-weight: 700;
}

.head-size{
    font-size: 6px;
    
}


.iqama2{
    width: 346px;
    height: 478px;
    top: 300px;
    left: 233px;
    position: absolute;
}

.time2{
    width: 346px;
    height: 55px;
    position: absolute;
    top: 260px;
    left: 259px;
}

.newtime{
    width: 346px;
    height: 55px;
    position: absolute;
    top: 260px;
    left: 41px;
}

.temv5{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 260px;
}

.temv6{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    bottom: 316px;
}

.temv7{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 536px;
}

.temv8{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 674px;
}

/* .iqama2-table tr th:first-child,
.iqama2-table tr td:first-child {
    background-color: #b2d0da;
}

.iqama2-table tr th:nth-child(3),
.iqama2-table tr td:nth-child(3) {
    background-color: #b2d0da;
}

.iqama2-table tr th:nth-child(4),
.iqama2-table tr td:nth-child(4) {
    background-color: #b2d0da;
    min-width: 80px !important;
    width: 80px;
}

.iqama2-table tr th:nth-child(5),
.iqama2-table tr td:nth-child(5) {
    background-color: #b2d0da;
}

.iqama2-table tr th:nth-child(7),
.iqama2-table tr td:nth-child(7) {
    background-color: #b2d0da;
}

.iqama2-table tr th:nth-child(2),
.iqama2-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqama2-table tr th:nth-child(6),
.iqama2-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqama2-table th,
.iqama2-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    font-family: Arial;
    font-weight: 700;
}

.head-size{
    font-size: 6px;
    
}


.iqamak1{
    width: 346px;
    height: 478px;
    top: 80px;
    left: 259px;
    position: absolute;
}

.timek1{
    width: 346px;
    height: 52px;
    position: absolute;
    top: 43px;
    left: 259px;
}

.temk1{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 43px;
}

.temk2{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 180px;
}

.temk3{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 317px;
}

.temk4{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    
    left: 44px;
    top: 453px;
}

/* .iqamak1-table tr th:first-child,
.iqamak1-table tr td:first-child {
    background-color: #b2d0da;
}

.iqamak1-table tr th:nth-child(3),
.iqamak1-table tr td:nth-child(3) {
    background-color: #b2d0da;
}

.iqamak1-table tr th:nth-child(4),
.iqamak1-table tr td:nth-child(4) {
    background-color: #b2d0da;
    min-width: 80px !important;
    width: 80px;
}

.iqamak1-table tr th:nth-child(5),
.iqamak1-table tr td:nth-child(5) {
    background-color: #b2d0da;
}

.iqamak1-table tr th:nth-child(7),
.iqamak1-table tr td:nth-child(7) {
    background-color: #b2d0da;
}

.iqamak1-table tr th:nth-child(2),
.iqamak1-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqamak1-table tr th:nth-child(6),
.iqamak1-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqamak1-table th,
.iqamak1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    font-family: Arial;
    font-weight: 700;
}

.head-size{
    font-size: 6px;
    background: #f5f5dc;
}

.ra-head-size {
    background-color: #fdfdf1;
}

/* virtual css */

.trow {
    width: 100%;
    display: flex;
    text-align: center;
}

.tcol {
    display:inline-block;
    width: 100%;
    border: 1px solid #b4b3b3;
    background: white;
    /* background: #f5f5dc; */
    padding-top: 3px;
}

.fajriqama-col {
    background: #FFE6EE;
    min-width: 60px !important;
    width: 60px;
}

.ra-fajriqama-col {
    min-width: 60px !important;
    width: 60px;
}


.maghribiqama-col {
    background: #e4f2db;
    min-width: 82px !important;
    width: 82px;
}

.ra-maghribiqama-col {
    min-width: 82px !important;
    width: 82px;
}

.dhuhr-col {
    min-width: 125px !important;
    width: 125px;
}

.asr-col {
    min-width: 60px !important;
    width: 60px;
}

.isha-col {
    min-width: 69.18px !important;
    width: 69.18px;
}

.date-col {
    min-width: 40px !important;
    width: 40px;
}

.day-col {
    min-width: 40px !important;
    width: 40px;
}

.sunrise-col {
    min-width: 40px !important;
    width: 40px;
}

.template-hide {
    height: 1px;
    overflow: hidden;
    opacity: 0;
}
  @media print {
    .template-hide {
      display: block;
      opacity: 1;
      overflow: visible;
      height: auto;
    }
  }

/*PRINT CSS*/
/* @media print {
    .temp {
        width: 15.7cm;
        height: 29.7cm;
        margin: 30mm 45mm 30mm 45mm;
    }
    .noprint {display:none;}
}

@page {
    size: A4;
    margin: 0;
  } */


  
  @media print  {
    @page {
      orientation: portrait;

    }
    body * {
      visibility: hidden;
    }
    .section-to-print, .section-to-print * {
      visibility: visible;
    }
    .section-to-print {
      position: absolute;
      
    }
  }



  .danger {
    background-color: #E5343D;
    border-color: #BF2D35;
    color: #fff;
    border-radius: 2px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.8;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    margin-top: 28px;
   
  }

  @media only screen and (max-width: 1440px) {
      .vieworder-scroll {
          overflow-x: scroll;
      }
  }

  @media only screen and (max-width: 1024px) {
    .vieworder-flex {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
  }

  .vieworder-img-active + img {
    outline: 68px solid rgba(210, 26, 26, 0.6);
    outline-offset: -68px;
    overflow: hidden;
    position: absolute;
  }


.fa-col {
    background: #FFE6EE;
    min-width: 41px !important;
    width: 41px;
}

.ra-fa-col {
    min-width: 41px !important;
    width: 41px;
}

.mag-col {
    background: #e4f2db;
    min-width: 50px !important;
    width: 50px;
}

.dhu-col {
    min-width: 70px !important;
    width: 70px;
}

.as-col {
    min-width: 45px !important;
    width: 45px;
}

.ish-col {
    min-width: 45px !important;
    width: 45px;
}

.dat-col {
    min-width: 30px !important;
    width: 30px;
}

.da-col {
    min-width: 30px !important;
    width: 30px;
}

.sun-col {
    min-width: 30px !important;
    width: 30px;
}

/* Lunar calendar css */

/* .tcoll {
    display:inline-block;
    border: 1px solid #b4b3b3;
    background: #f5f5dc;
    padding-top: 3px;
} */

.tcoll {
    display:inline-block;
    /* width: 100%; */
    border: 1px solid #b4b3b3;
    /* background: white; */
    background: #fdfdf1;
    padding-top: 3px;
}

#fr-tcoll {
    display:inline-block;
    /* width: 100%; */
    border: 1px solid #b4b3b3;
    background: #d2d1d0;
    padding-top: 3px;
    display: flex;
}

#n-fr-tcoll {
    background: #d2d1d0;
}

.ra-tcoll {
    display:inline-block;
    /* width: 100%; */
    border: 1px solid #b4b3b3;
    /* background: #f5f5dc; */
    padding-top: 3px;
}

.dhuhrr-col {
    min-width: 72px !important;
    width: 72px;
}

.datee-col {
    min-width: 40px !important;
    width: 40px;
}

.dayy-col {
    min-width: 31px !important;
    width: 31px;
}

.sunrisee-col {
    min-width: 33px !important;
    width: 33px;
}

.magg-col {
    background: #e4f2db;
    min-width: 46.6px !important;
    width: 46.6px;
}

.ra-magg-col {
    min-width: 46.6px !important;
    width: 46.6px;
}

.dhuu-col {
    min-width: 50px !important;
    width: 50px;
}

.ass-col {
    min-width: 39px !important;
    width: 39px;
}

.daa-col {
    min-width: 21px !important;
    width: 21px;
}

/*Lunar month css*/
.lun-col {
    min-width: 42px !important;
    width: 42px;
}

.lunar-col {
    min-width: 60px !important;
    width: 60px;
}

/*Ramadan month css*/
.ramadan-col {
    /* display: inline-block; */
    min-width: 21px !important;
    width: 21px;
}

.ramadaan-col {
    min-width: 30px !important;
    width: 30px;
}

.center-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1.5px;
}