table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-radius: 1rem;
}
td, th {
  border: 1px solid #dddddd;
  /* text-align: left; */
  padding: 8px;
}

tr:nth-child(even) {
  /* background-color: #dddddd; */
  border-collapse: collapse;
}

button {
    width: auto;
    height: auto;
}

#center {
  margin: auto;
  margin-top: 3rem;
  width: 80%;
  border: solid 1px white;
  padding: 60px;
  background-color: white;
  border-radius: 1rem;
}

.mosque-table {
  width: 100%;
  overflow-x: scroll;
}

.batch-table {
  width: 100%;
  overflow: scroll;
}