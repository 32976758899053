.tab-list {  border-bottom: 1px solid #ccc;
    padding-left: 0;
    margin-top: 20px;
    margin-left: 50px;
  }
  
  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    
  }
  
  .tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    
  }

  .entry-title-payment {
    padding-top: 18px;
    font-family: Arial;
    font-size: 24px;
    /* padding-left: 180px; */
  }

  @media only screen and (min-width: 2560px) {
    /* .entry-title-payment{
      padding-left: 430px;
    } */
  }