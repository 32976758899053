@font-face {
  font-family: 'WebFonts';
  src: url(../../../../assets/fonts/AlegreyaSans-Medium.woff);
}


  .modal {
    width: 40%;
    height: 65%;
    margin-left: 25%;
    border-radius: 0px;
    box-sizing: border-box;
    display: block;
    -webkit-box-direction: normal;
  }

  .modheader {
    color: #374767;
  }

  .mos {
    font-weight: 400 !important;
    font-size: 14px;
    /* display: inline-block; */
    max-width: 100%;
    margin-bottom: 5px;
    color:#5a5858;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  }

  .mos1 {
  cursor: text;
  font-family: 'WebFonts';
  /* font-size: 10px; */
  color: #5a5858;
  width: 100%;
  border-radius: 2px;
  padding: 4px 3px 4px;
  border: 1px solid #e4e7eb;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .mos2 {
    color: #333333;
    font-weight: bold;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  }

  .mos3 {
    width: 100%;
    height: 100%;
    border: 1px solid #e4e7eb;
  }

  .que::-webkit-calendar-picker-indicator {
    opacity: 10;
   background-color: #e4e7eb;
    padding: 15px 9px 15px 9px;
 }

 .que1::-webkit-calendar-picker-indicator {
  opacity: 10;
 background-color: #e4e7eb;
  padding: 15px 9px 15px 9px;
}

.tog {
  float: right;
  padding: 6px 12px;
  color: white;
  background-color: #558B2F!important;
  font-size: 14px;
  border-radius: 2px;
  border-color: #3C6F18;
  text-align: center;
}
  
.tog1 {
  background-color: #f7f9fa;
    /* border-top: 1px solid #e1e6ef; */
    padding: 10px 15px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    /* margin-top: -4%; */
}

/* .scrollable {
  overflow-y: auto;  
} */