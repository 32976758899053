div#root {
  max-width: 1920px;
  margin: 0 auto;
}

@font-face {
  font-family: 'PCFonts';
  src: url(/static/media/AlegreyaSans-Medium.7b2f33e7.woff) , 
       url(/static/media/AlegreyaSans-Medium.5a3f02a7.ttf)  ,
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  border: 3px solid #fff;
  padding: 20px;
  height: 90vh;
  justify-content: center;
  font-size: 3rem; 
}

.playstore-text h2{
        color: #000;
        font-size: 26px;
        margin-bottom: 0px;
        margin-left: 8px;
    }
.playstore-text p{
        color: #000;
        font-size: 14px;
        line-height: 1.5;
        margin-left: 8px;
    }

    .marketing-banner{
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      margin-bottom: 30px;
      padding-bottom: 20px;
    }

    .offer-box {
      border: 1px solid #c9c9c9;
      background: linear-gradient(
  268deg
  , #eeeeee, transparent);
      margin-bottom: 40px;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }

          .offer-box h5{
            font-weight: 800;
            font-size: 30px;
            color: #fff;
            background: #0bb0b6;
            padding: 10px 20px;
          }

.home {
    background-color: white;
}

.masjid1 {
    width: 360px;
    height: auto;
    display: flex;
    margin-left: 30px;
    padding-right: 35px;
    margin-top: 10px;
    /* padding:5px; */
}

#plcs {
    border: solid 1px white;
    width: 95%;
    margin-left: 25px;
    height: 20px;
    text-transform: capitalize;
    font-size: 20px;
    color: #D21A1A;
}

#plcs1 {
    border: solid 1px white;
    width: 95%;
    margin-left: 25px;
    height: 20px;
    font-size: 15px;
    color: #696c70;
}

#pls {
    border: solid 1px white;
    width: 100%;
    margin-left: 38px;
    color: #3f3f41;
    font-weight: bold;
    font-size: 15px;
    /* margin-top: 30px; */
}

.btnhome {
    /* padding: 2px 5px 2px 5px; */
    padding: 7px 10px;
    /* padding-left: none; */
    /* margin-left: 126px; */
    border-radius: 2px;
    outline: none;
    border: none;
    font-size: 14px;
    color: #323232;
    cursor: pointer;
    background-color: #ffbc00;
    border: 1px solid #fff;
    /* width: 120px;
    height: 30px; */
    margin-bottom: 10px;
}

.custom-toggle {
    position: relative;
    width: 1px!important;
    z-index: 10;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 40%);
}

.custom-toggle span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 100;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
}
.custom-toggle span i {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #D21A1A;
}

.hidden.custom-toggle span i {
    -webkit-transform: rotate(
180deg
) translate(+50%, -20%,);
            transform: rotate(
180deg
) translate(+50%, -20%,);
}

.btnhome:hover{
    border: 1px solid #ffbc00;
    color: #ffbc00;
    background: #fff;
}

.masjids {
    width: 360px;
    margin-top: 5px;
    display: flex;
    border-color: #e1e1e1;
    border-style: solid;
    border-width: 0;
    zoom: 1;
}

.masjid2 {
    width: 360px;
    height: auto;
    margin-top: 20px;
}

.div1 {
    width: 30%;
    height: auto;
}

/* .cardd {
    padding-top: 10px;
    margin-top: 30px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, .97);
    border: solid 1px #e5e5e5;
    border: solid 1px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 3px rgba(48, 48, 48, .1);
    -moz-box-shadow: 0 3px rgba(48, 48, 48, .1);
    box-shadow: 0 3px rgba(48, 48, 48, .1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
} */
.cardd-logo {
    border-radius: 50px;
    height: 70px;
    width: 70px;
    margin-right: 15px;
}

.cardd{
    padding-top: 15px;
    padding-bottom: 5px;
    margin-top: 30px;
    margin: 20px 0px 10px 10px!important;
    background-color: #fff;
    background-color: hsla(0,0%,100%,.97);
    border: 1px solid #e5e5e5;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 2px 6px rgb(48 48 48 / 10%);
    border-radius: 3px;
    border-top: 3px solid #FFBC00
}

/* .cardd-logo{    
    margin-right: 15px;
} */

.div2 {
    width: 108%;
    float: left;
    height: 550px;
}

.card-form {
    width: calc( 100% - 80px );
    color: #707070;
}



/* width */
::-webkit-scrollbar {
    width: 5px;
    
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white; 
    
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5; 
    border-radius: 5px;
    
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white; 
    height: 5px;
  }

  
  .headad1 {
      margin-left: 88%;
      padding-top: 16px;
  }
  @media only screen and (min-width: 280px) and (max-width: 320px) {
      .cardd-logo {
          height: 50px;
          width: 50px;
      }
  }
  /* .headad2 {
      height: 60px;
      border-right: none;
      border-top: none;
      padding-left: 65px;
      padding-right: 50px;
      background-color: #EEF2F6;
  } */
  /* @media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
   .headad1 {
       margin-left: 70%;
   }
   .headad2 {
       margin-left: -130px;
       margin-right: -115px;
   }
} */

.sidebar-card-title {
    color: #707070;
    font-weight: 500;
    margin-bottom: 3px;
    font-size: 18px;
}

.map-wrapper > div, .map-wrapper{
    width: 100%;
    height: 100%;
}



/*New Services css*/
.service-selector{
    border: 1px solid #FFDC7B;
    padding: 40px 20px;
  }  

  .service-title h3{
    font-size: 28px;
    margin: 10px auto;
    font-weight: 500 !important;
    color: #707070;
    text-align: center;
    margin-bottom: 30px;
  }

  .service-box {
    padding: 10px;
    margin: 10px;
    width: 100%;
    max-width: 220px;
  }

  .service-box a {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 24px rgb(0, 0, 0, 0.1);
    border-top: 3px solid #FFBC00;
    padding: 50px 10px 10px;
  }

  .service-box a:hover{
    box-shadow: 0px 2px 12px rgb(0, 0, 0, 0.1);
    border: 2px solid #FFBC00;
  }

  .service-box img {
    width: 100px;
    margin-bottom: 10px;
  }

  .service-box h4 {
    text-align: center;
    height: 60px;
    color: #0BB0B6;
    font-size: 19px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
/*New Services css END*/

/*Print on Your own css*/
.icon-text {
    /* padding-left: 3.9rem; */
    align-items: baseline;
    justify-content: center;
}

/* .icon-text2 {
    padding-left: 3.9rem;
} */

.detail {
    padding-left: 10px;
}

@media screen and (max-width: 766px) {
    .detail {
        text-align: center;
        /* padding-left: 0px !important; */
    }

    .icon-icon {
        text-align: center;
    }

    .upload-text {
        display: flex;
        justify-content: center;
    }
}

.page-text {
    color: #2C3E50;
    font-size: 21px;
    border-bottom: 2px solid rgb(227, 227, 227);
    padding-top: 40px;
    font-weight: bold;
}

.shipping-method {
    font: normal normal 600 14px/23px Mulish;
    letter-spacing: 0px;
    color: #07411A;
    opacity: 1;
}

.Method-box {
    border: 2px solid #CED4DA;
    background: #FFFFFFFA 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 40px;
}

.method-type {
    font: italic normal 600 15px/20px Mulish;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.method-price {
    font: normal normal 600 17px/28px Mulish;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.method-price2 {
    text-align: left;
    font: normal normal 600 17px/28px Mulish;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-left: 12rem;
}

.method-price3 {
    text-align: left;
    font: normal normal 600 17px/28px Mulish;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-left: 8.8rem;
}

.upload-text {
    text-align: left;
    font: normal normal normal 15px/19px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    padding-left: 1rem;
}

.upload-box {
    background: #FFFFFFFA 0% 0% no-repeat padding-box;
    border: 1px solid #FFBC00;
    opacity: 1;
    padding: 15px;
    margin: 10px;
    width: 100%;
    /* max-width: 500px; */
}

.formcol {
    display: flex;
    justify-content: center;
}

.dashed-line {
    border-bottom: 2px dashed rgb(227, 227, 227);
}

.title-price {
    text-align: center;
    font: normal normal medium 18px/22px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.price-box {
    border: 2px solid #CACACA;
    opacity: 1;
    /* margin: 10px; */
    margin-left: 30px;
    width: 100%;
    max-width: 140px;
}

.total-price {
    text-align: center;
    font: normal normal 600 25px/40px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    /* margin-left: 2rem; */
}

.pin-box {
    border: 2px solid #CACACA;
    opacity: 1;
    margin-bottom: 10px;
    margin-left: 10px;
    width: 100%;
    max-width: 100px;
}

.pin-no {
    text-align: center;
    font: normal normal 600 15px/30px Roboto;
    letter-spacing: 0px;
    color: gray;
    opacity: 1;
}

@media print {
    .pdf-container {
      display: none;
    }
  }
/*Print on Your own css END*/

/*Choose from our Design css*/
.template-select-box {
    padding: 10px;
    margin: 10px;
    width: 100%;
    max-width: 220px;
  }

  .template-select-box a {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E1E1E1;    
  }

  .template-select-box a:hover{
    box-shadow: 0px 2px 12px rgb(0, 0, 0, 0.1);
    border: 2px solid #FFBC00;
  }

  .template-select-box img {
    width: 100%;         
  }

  .check-new.custom-control{
    padding: 0px 4px;
  }

  .check-new .custom-control-label::before {
      border: 1px solid #707070;  
      left: 9px;
      top: 9px;  
      height: 20px;
      width: 20px;     
  }

  .check-new .custom-control-label::after {
    left: 15px;
    top: 15px;
    border-radius: 50%;
    width: 9px;
    height: 9px;
  }

  .check-new.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
      background: #fff;
      border-color: #FFBC00;
  }

  .check-new.custom-radio .custom-control-label{
      padding: 5px 14px 10px;            
      padding-left: 36px;
      border: 1px solid #E1E1E1;
      font-size: 16px;
      font-weight: 500;
  }

  .check-new.custom-radio .custom-control-input:checked ~ .custom-control-label{
    background: #FFBC00;
    border: 1px solid #FFBC00;
    color: #fff;
  }

  .check-new.custom-radio .custom-control-input:checked~.custom-control-label{
      border: 1px solid green;
  }

  .check-new.custom-radio .custom-control-input:checked~.custom-control-label::after {
      background: #0BB0B6;
      background-repeat: no-repeat;
      background-position: 4px 7px;
  }  

  /*Choose from our Design css END*/

  /*Work with designer service css*/
  .post-box {
    padding: 10px;
    margin: 10px;
    width: 100%;
    max-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 24px rgb(0, 0, 0, 0.1);
    border-top: 3px solid #FFBC00;
    padding: 50px 10px 10px;
  }

  .post-box img {
    width: 100px;
    margin-bottom: 10px;
  }

  .post-box h4 {
    text-align: center;
    height: 60px;
    color: #0BB0B6;
    font-size: 19px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  /* .post-box a {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 24px rgb(0, 0, 0, 0.1);
    border-top: 3px solid #FFBC00;
    padding: 50px 10px 10px;
  }

  .post-box a:hover{
    box-shadow: 0px 2px 12px rgb(0, 0, 0, 0.1);
    border: 2px solid #FFBC00;
  } */
  /*work with designer service css END*/
:root {
  --primary: #1888ff;
}

.btnnav{
  background-color:#D21A1A;
  border-radius: 2.5px; 
}

.btnnav:hover {
  background-color: #dd4b4b;
}


.headerlog:hover{
  background-color: #dd4b4b;
}

.headerlog{
  background-color: #D21A1A;
}
.dropbtn {
  color: #d21A1A;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  
}




/* The container <div> - needed to position the dropdown content */
.dropdown {
  display: inline-block;
  color: #D21A1A;
}




/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px #D21A1A; */
  z-index: 1;
}



/* Links inside the dropdown */
.dropdown-content a {
  /* color: #D21A1A; */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: white;
  
}




/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: white;
text-decoration: none;}



/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}



/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #D21A1A;
color:white;}



.dropbtn1 {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  background-color: transparent;
}


.butoanc1 {
  text-decoration: none;
  color: white;
  font-size: 16px;
  border: none;
  
}

.dropbtn2 {
    background-color: #D21A1A;
    height: 30px;
    width: 60px;
    border-radius: 2px;
    color: white;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.dropbtn1:hover {
  background-color: #D21A1A;
  color:white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
} 
.butoanc2:hover {
  text-decoration: none;
  background-color: #D21A1A;
  font-size: 16px;
  border: none;
}

.logout-button {
  color: #0bb0b6;
  background: #fff;
  border: 1px solid #0bb0b6;
  margin-right: 15px;
}

.logout-button:hover{
  background: #0bb0b6;
  color: #fff;
}

.myaccount-button{
  color: #fff;
  background: #0bb0b6;
  border: 1px solid #0bb0b6;       
}

.myaccount-button:hover{
  background: #fff;
  color: #0bb0b6;
}

.navbarr {
  background: white;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border: 1px solid rgb(209, 204, 204);
  width: auto;
}

.navbarr-logo {
  color: black;
  justify-self: start;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-weight: bolder; 
  max-width: auto;
  height: auto;
}


.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  
  width: 65vw;
  justify-content: end;
  margin-right: 2rem;
  margin-top: 42px;
}
.map-search {
  height: 80px;
  padding-top: 25px;
  z-index: 1;
  width: 600px;
}
.search-maploc {
  border-color: #e1e1e1;
  border-style: solid;
  margin-left: 0px;
  width: 540px;
  height: 40px;
  font-family: Arial;
  box-sizing: border-box;
}
.masajid-logo {
  width: 300px;
  /* margin-right: 20px; */
}
.butt-go {
  /* margin-top: 215%; */
  background: none;
  color: #0bb0b6;
  /* float: right; */
  /* margin-right: 730%; */
  font-size: 16px;
  border: none;
  font-family: Arial;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 20px;
}

.search-logo1 {
  height: 41px;
  width: 47px;
}
/* @media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
  
  
  .map-search {
    margin-top: 100px;
    padding-left: 250px;
  }
  .navbarr {
    height: auto;
    width: auto;
  }
  .navi-btn {
    padding-top: 48px;
    margin-right: 50px;
  }
} */
.navi-btn {
  margin-top: 23px;
  margin-left: 0px;
  display: flex;
}

.pc{
  height: 30px;
  width: 100px;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  
}

.nav-links {
  /* color: black; */
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #003d7a;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  color: white;
}

.fa-bars {
  color: #fff;
}

.nav-links1-mobile {
  display: none;

}

.menu-icon {
  display: none;
}


.nav-button {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-button1 {
  display: flex;
  align-items: center;
  height: 80px;
  padding-top: 25px;
}

.dropbtn1 {
  font-size: 10px;
}

.wrap1 {
  display: flex;
  align-items: center;
  height: 80px;
}
.search-maploc {
  width: 430px;
}

.dropbtn {
  color: black;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  
}




/* The container <div> - needed to position the dropdown content */
.dropdown {
  display: inline-block;
}




/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  background-color: transparent;
  width: 200px;
}



/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;border-radius: 1px;
}




/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;
text-decoration: none;}



/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}



/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color:#D21A1A;
color:white;}



.dropbtn1 {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  background-color: transparent;
}


.butoanc1 {
  text-decoration: none;
  color: white;
  font-size: 16px;
  border: none;
  
}

.dropbtn2 {
    background-color:#D21A1A;
    height: 30px;
    width: 60px;
    border-radius: 2px;
    color: white;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.dropbtn1:hover {
  background-color:#D21A1A;
  color:white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
} 


.dropbtn11 {
  padding: 10px;
  margin-bottom: 25px;
  
  border: none;
  background-color: transparent;
}

.dropbtn11:hover {
  background-color:#D21A1A;
  color:white;
  text-decoration: none;
  cursor: pointer;
  
  height: 44px;
} 

.search-logo {
 text-align: center;
 padding-top: 10px;
}
.search-logo-row {
  padding-left: 80px;
}
.search-logo-img {
  height: 41px;
  width: 47px;
  margin-right: 10px;
}

::-webkit-input-placeholder {
  color: #9e9d9d;
  font-weight: 450;
  font-size: 16px;
  padding-left: 5px;
  font-family: Arial
  
}

::-webkit-input {
  border-radius: none;
}
.searchinput {
  border-color: #e1e1e1;
  border-top-color: #e1e1e1;
  
}




.newheader {
    background: rgba(255,255,255,.95);
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
    z-index: 10;
    position: relative;
    height: 90px;
    padding: 0;
    width: 100%;
}


.logoimage {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
}

.searchinput {
    box-sizing: border-box;
    height: 40px;
    /* padding-right: 45px; */
    /* margin: 0; */
    width: 91%;
}

/* .focus:focus {
  outline: 1px solid #e1e1e1;
} */
.focus {
  border-color: #e1e1e1;
  border-style: solid;
  width: 100%;
  height: 40px;
  font-family: Arial;
  box-sizing: border-box;
  /* float: left; */
  /* margin-left: 17%;
  vertical-align: top; */
}

@media only screen and (min-width: 280px) and (max-width: 320px) {
  .logoimage {
   /* float: left; */
   /* margin-left: 3%; */
   padding-bottom: 5%;
  }
  .focus {
    width: 100%;
    height: 40px;
    /* margin-bottom: 2%; */
    /* margin-left: 2.5%; */
  }
  .search-logo {
    padding-top: 0px;
  }
  .search-logo-row {
    padding-left: 0px;
  }
  .newheader {
    height: auto;
  }
  .search-logo-img {
    display: none;
  }
  /* .butt-go {
    margin-top: -13%;
    float: left;
    margin-left: 265px;
  } */
  
}

@media only screen and (min-width:321px) and (max-width:767px) {
  .logoimage {
    /* float: left;
    margin-left: 3%; */
    padding-bottom: 0%;
   }
   .newheader {
    height: 60%;
  }
  .search-logo-img {
    display: none;
  }
  .focus {
    width: 100%;
    height: 40px;
    /* margin-bottom: 2%;
    margin-left: -23%; */
  }
  /* .butt-go {
    margin-top: -180%;
    float: left;
    margin-left: 1555%;
  } */
  
}
@media only screen and (width: 768px) {
  .search-logo-row {
    padding-left: 10px;
  }
  /* .focus {
    margin-left: 10%;
  } */
  
}
@media only screen and (min-width:1440px) {
  .logoimage {
    
    /* margin-left: 50%; */
    padding-bottom: 0%;
   }
   /* .butt-go {
     margin-top: 45px;
    margin-left: -3px;
  } */
  
}

/* @media only screen and (min-width: 768px) {
  .newheader {
    height: auto;
  }
  .logoimage {
    float: left;
    margin-left: 15%;
    margin-bottom: 5%;
   }

   .focus {
    width: 140%;
    height: 40px;
    margin-left: 10%;
  }
  .search-logo-img {
    float: left;
  }
  
  .butt-go {
    margin-top: 315%;
    margin-right: 5%;
  }
} */
@media only screen and (min-width:769px) and (max-width:1260px) {
  /* .search-logo-img {
    float: left;
  } */
  /* .search-logo {
    margin-left: -9%;
    margin-top: 1%;
  } */
  .focus {
    width: 100%;
    height: 40px;
    /* margin-left: 5%; */
  }
  
   /* .butt-go {
    margin-top: 300%;
    margin-right: -20%;
  } */
}
@media only screen and (min-width: 2560px) {
  /* .search-logo-img {
    float: left;
  } */
  .focus {
    width: 100%;
    height: 40px;
    /* margin-left: -5%; */
  }
  
  /* .butt-go {
    float: right;
    margin-right: 2999%;
  } */
   .newheader {
    margin-left: 8.5%;
  }
}
/* @media only screen and (min-width:321px) and (max-width:768px) {
  .butt-go {
    margin-right: 100px;
  }
  .search-logo1 {
    float: right;
  }
}
@media only screen and (min-width:769px) and (max-width:1260px) {
  .butt-go {
    margin-left: 200px;
  }
  .search-logo1 {
    float: right;
  }
}
@media only screen and (max-width:1440px) {
  .butt-go {
    margin-left: 475px;
  }
}
@media only screen and (min-width: 2560px) {
  .butt-go {
    margin-left: 945px;
  }
} */

.header-search {
  width: 100%;
  height: 40px;
  position: relative;
}

.search-wrapper {
  width: 100%;
}

.fa-search {
  color: #bbb;
} 

.focus{
  border-radius: 3px;
}
.foot{
    /* background-color: #EEF2F6; */
    padding: 60px;
    padding-left: 150px;
    /* padding-bottom: 10px; */
    /* height: 250px; */
    width: auto;
    background-color: #0BB0B6;
    border-color: #e1e1e1;
    border-style: solid;
    border-width: 0;
    zoom: 1;
    color: #3d3d3d;
    line-height: 30px;
    /* position: absolute; */
    bottom: 0px;

}

.foot h5 {
    position: relative;
    line-height: 1;
    padding-bottom: 15px;
    margin: 0px 0px 8px;
    background: none;
    color: #fff;
}
.foot h5::after {
    position: absolute;
    width: 40px;
    height: 2px;
    z-index: 10;
    background: #FFBC00;
    content: '';
    bottom: 0px;
    left: 0px;
}

.copy{
    background-color: #333;
    padding: 20px 20px 20px 20px;
    color: #777;
    /* padding-left: 150px; */
    /* font-size: 13px; */
    line-height: 24px;
    margin: 0;
    min-height: 24px;
    
    position: relative;
}
.sup{
    color:#fff;
    font-size: 15px;
    font-family: Arial,sans-serif;
    margin-left: 0px;
    
}
.icns{
    padding: 10px;
    
}
.abt{
    font-family: Arial,sans-serif;
    font-size: 22px;
    /* line-height: 30px; */
    position: relative;
    font-weight: normal;
    line-height: 1px;
    margin-left: 4px;
    background-color:  #eef2f6;
    /* border-bottom: 2px solid #e3e3e3; */

}
.admnfoot{
    background-color: #EEF2F6;
    padding: 5px;
    
}
.txt{
    /* text-align: center; */
    font-size: 14px;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
}



#site-footer{
    font-size: 15px;
    line-height: 24px;
    position: relative;
    margin-top: auto!important;
    display: block;
}

#site-footer .top-box {
    background-color: #eef2f6;
    padding: 40px 0 40px;
    
}

.container {
    margin-right: auto;
    margin-left: auto;
    
}

/* @media (min-width: 1200px){
.container {
    width: 1170px;
}
} */




#site-footer .title, #site-footer .widget-title {
    /* border-bottom: 2px solid #e3e3e3; */
    font-size: 22px;
    font-weight: normal;
    line-height: 30px;
    margin: 0 0 15px;
    padding: 0 0 14px;
    position: relative;
}
.foot-img {
    width: 430px;
    height: 20px;
}
.wel-img {
    width: 200px;
}
.back-color-foot {
    background-color:  #eef2f6;
}
@media only screen and (min-width: 280px) and (max-width: 320px) {
    .foot {
        padding-left: 0px;
    }
    .termser {
        margin-top: 15px;
    }
    .abt {
        margin-left: 8px;
    }
    /* .copy {
        padding-left: 30px;
        padding-bottom: 60px;
    } */
    .back-color-foot {
        background-color:  #eef2f6;
    }
}

@media only screen and (min-width:321px) and (max-width:767px) {
    .foot {
        padding: 20px;
    }
    .termser {
        margin-top: 15px;
    }
    .abt {
        margin-left: 8px;
    }
    /* .copy {
        margin-top: 100%;
        padding-left: 30px;
        padding-bottom: 60px;
    } */
    .back-color-foot {
        background-color: none;
    }
}

@media only screen and (width: 768px) {
    .foot {
        padding-left: 3%;
    }
    /* .copy {
        margin-top: 15%;
    } */
    .btrees-foot {
        margin-left: -100%;
    }
  }
/* .contact {
    margin-top: 0%;
    margin-right: none;
} */
/* @media only screen and (min-width: 768px) {
    .foot {
        padding-left: 1%;
    }
    .contact {
        margin-top: 0%;
        margin-right: 40%;
    }
} */

@media only screen and (min-width:769px) and (max-width:1260px) {
    /* .abt {
        margin-left: -20px;
    }
    .wel-img {
       margin-right: 10%;
    }
    .sup {
        margin-right: 20%;
    } */
    /* .copy {
        margin-top: 10%;
    } */
}
@media only screen and (min-width: 2560px) {
    .foot {
        margin-left: 10%;
    }
}
/* @media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
    .foot {
     height: auto;
     width: auto;
    }
    .foot-img {
        width: 430px;
        height: 20px;
    }
    .wel-img {
        width: 200px;
    }
    .termser {
        margin-top: 15px;
    }
} */
.tab-list {  border-bottom: 1px solid #ccc;
    padding-left: 0;
    margin-top: 20px;
    margin-left: 50px;
  }
  
  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    
  }
  
  .tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    
  }

  .entry-title-payment {
    padding-top: 18px;
    font-family: Arial;
    font-size: 24px;
    /* padding-left: 180px; */
  }

  @media only screen and (min-width: 2560px) {
    /* .entry-title-payment{
      padding-left: 430px;
    } */
  }
body {
  background-color: white;
  overflow-x: hidden;
}

div#root {
  max-width: 1920px;
  margin: 0 auto;
}


.loginh1{
  font-size: 24px;
  padding-top: 20px;
  font-family: Helvetica;
  font-weight: 100;
  padding-left: 55px;
  /* padding-bottom: 30px; */
  
}

.loginh2{
  
  padding-top: 6px;
  
  font-size:32px;
  font-weight:600;
}


.username {
  width: 90%;
  padding: 12px ;
  margin: 0px 0px 8px;
  cursor: text;
  box-sizing: border-box;
  
  border-radius: 2px;
  
  height: 40px;
  color: rgb(11, 11, 11);
  font-family: Helvetica;
  margin-left: 10px;
  border: #e1e1e1 solid 2px;
  margin-top: 50px;
}

.passwordlog{
  width: 90%;
  padding: 12px ;
  margin: 0px 0px 8px;
  box-sizing: border-box;
  
  border-radius: 2px;
  
  color: rgb(10, 10, 10);
  height: 40px;
  font-family: Helvetica;
  margin-left: 10px;
  border: #e1e1e1 solid 2px;
  margin-top: 12px;
}

.submitbuto{
  background-color: #D21A1A;
  color: #fff;
  padding: 10px;
  
  text-decoration: none;
  display: inline-block;
  
  cursor: pointer;
  border-radius: 2px;
  font-family:  Helvetica;
  margin-left: 0px;
  width: 90%;
  font-weight: bolder;
  border-color:#D21A1A;
  border: none;

  margin-left: 10px;
  margin-top: 10px;
}


.submitbuto1{
  background-color:#D21A1A;
  color: white;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 0px 0px;
  cursor: pointer;
  
  font-family:  Helvetica;
  margin-left: 22px;
  width: 75%;
  font-weight: bolder;
  border-color: rgba(27,31,35,.15);
  
  margin-left: 20px;
}

.submitbuto2{
  background-color:#D21A1A;
  color: white;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 0px 0px;
  cursor: pointer;
  
  font-family: Helvetica;
  
  width: 50%;
  font-weight: bolder;
  border-color: rgba(27,31,35,.15);
  box-shadow: 0 1px 0 rgba(27,31,35,.04), inset 0 1px 0 hsla(0,0%,100%,.25);
  transition: background-color .2s cubic-bezier(.3,0,.5,1);
  
  margin-left: 80px;
}


.forgot {
  /* width: 100%; */
  /* padding: 12px 20px; */
  /* margin: 8px 0; */
  cursor: pointer;
  /* height: 40px; */
  font-family:  Helvetica;
  
}




.space {
  font-weight: 400;
  /* margin-left: 10px; */
  font-size: 17px;
}

/* .space1 {
  margin-left: 25px;
} */
  .submitbuto:hover {
    background-color: #dd4b4b;
  }



.lef1 {
 
  width: 100%;
  margin-left: 145px;
 
} 


.lef2 {
 
  width: 100%;
  line-height: 25px;
  font-family: Helvetica;
  font-weight: 500;
}

.para1 {
  /* margin-bottom: 26px; */
  font-size: 17px;
  color: #111111;
}

 


 .submit {
  background-color: #2EA44F;
  
  color: white;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 0px 0px;
  cursor: pointer;
  border-radius: 5px;
  font-family:  Arial,sans-serif;
  margin-left: 22px;
  width: 200px;
  font-weight: bolder;
  
  border-color: rgba(27,31,35,.15);
  box-shadow: 0 1px 0 rgba(27,31,35,.04), inset 0 1px 0 hsla(0,0%,100%,.25);
  transition: background-color .2s cubic-bezier(.3,0,.5,1);
 }

 .submit:hover {
  background-color: #1b612f;
 }


 .text {
  width: 20%;
  padding: 12px 20px;
  margin: 8px 0;
  cursor: text;
  box-sizing: border-box;
  border: solid rgba(238, 232, 232, 0.678);
  border-radius: 5px;
  background-color: rgb(255, 249, 249);
  height: 40px;
  color: rgb(10, 10, 10);
  font-family:  Arial,sans-serif;
 }
 .lgin{
   background-color: #EEF2F6;
   padding: 10px 0px 10px;
   /* padding-left: 187px; */
   /* margin-left: 185px; */
   font-size: 25px;
   margin: 0 0 15px;
   position: relative;
   font-weight:bold;
   /* z-index: 2; */
 }
 .lgin1r{
  background-color: #EEF2F6;
  padding: 10px 0px 10px;
  /* padding-left: 187px; */
  /* margin-left: 185px; */
  font-size: 25px;
  margin: 0 0 15px;
  position: relative;
  font-weight:bold;
  /* z-index: 2; */
}

 .log {
  border: 1px solid #e5e5e5;
  border-bottom-color: transparent;
  margin-top: 30px;
  /* margin-left: 40px; */
  padding-bottom: 20px;
  /* width:70%; */
  background-color: rgba(255,255,255,.97);
  box-shadow: 0 3px rgba(48,48,48,0.1);
 }

 .submitbuto2:hover {
  background-color: #dd4b4b;
}

.submitbuto:hover {
  background-color: #dd4b4b;
}




.new-checkbox  {
  border: 2px solid #e3e3e3;
    border-radius: 2px;
    cursor: pointer;
    float: left;
    height: 16px;
    
    position: relative;
    width: 16px;
    margin-left: 10px;
   
    height: 25px;
}




/* body {
  font: 0.8em arial, helvetica, sans-serif;
} */

#header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#header li {
  float: left;
  border: 1px solid;
  border-bottom-width: 0;
  margin: 0 0.5em 0 0;
}

#header a {
  display: block;
  padding: 0 1em;
}

#header #selected {
  position: relative;
  top: 1px;
  background: white;
  height: 60px;
  width: 120px;
  border-radius: 3px;
  padding-left: 28px;
  padding-top: 5px;
  font-size: 26px;
  border: 1px solid #e5e5e5;
  border-bottom: none;
}
#header #selected1r {
  position: relative;
  top: 1px;
  background: white;
  height: 60px;
  width: 240px;
  border-radius: 3px;
  padding-left: 28px;
  padding-top: 5px;
  font-size: 26px;
  border: 1px solid #e5e5e5;
  border-bottom: none;
}

#content-l {
  border: 1px solid #e5e5e5;
  clear: both;
  width: 85%;
  height: 300px;
  border-radius: 3px;
  box-shadow: 0 3px rgba(48,48,48,.1);
  -webkit-box-shadow: 0 3px rgba(48,48,48,.1);
}
#content1r {
  border: 1px solid #e5e5e5;
  clear: both;
  width: 480px;
  height: 260px;
  border-radius: 3px;
  box-shadow: 0 3px rgba(48,48,48,.1);
  -webkit-box-shadow: 0 3px rgba(48,48,48,.1);
}

h1 {
  margin: 0;
  /* padding: 0 0 1em 0; */
}

.whole {
  margin-left: 40px;
  margin-top: 10px;
}

/* @media only screen {
  body {
    overflow-x: hidden !important;
  }
} */
@media only screen and (min-width: 280px) and (max-width: 320px) {
  #content-l {
    width: 100%;
    height: 320px;
  }
  #content1r {
    width: 297px;
    height: 320px;
  }
  /* .lgin {
    padding-left: 40%;
  }  */
  /* .lgin1r {
    padding-left: 20%;
  }  */
  .lef1 {
    margin-left: -16%;
  }
  .lef2 {
    margin-top: 20%;
  }
}
@media only screen and (min-width:321px) and (max-width:767px){
  #content-l {
    width: 100%;
    height: 300px;
  }
  #content1r {
    width: 400px;
    height: 300px;
  }
  .lgin {
    /* padding-left: 35px; */
    font-size: 35px;
  } 
  /* .lgin1r {
    padding-left: 20%;
  }  */
  .lef1 {
    margin-left: -10%;
  }
  .lef2 {
    margin-top: 8%;
  }
}
@media only screen and (width: 768px) {
  #content-l {
    width: 100%;
    height: 300px;
  }
  #content1r {
    width: 350px;
    height: 300px;
  }
  .lef1 {
    margin-left: -5%;
  }
  .lef2 {
    margin-top: 8%;
  }
}
@media only screen and (min-width:769px) and (max-width:1260px){
  #content-l {
    width: 100%;
    height: 260;
  }
  #content1r {
    width: 400px;
    height: 260;
  }
  .lgin {
    /* padding-left: 55px; */
    font-size: 35px;
  }
  /* .lgin1r {
    padding-left: 5%;
  }   */
  .lef1 {
    margin-left: 0px;
  }
  .lef2 {
    float: right;
  }
}
@media only screen and (min-width: 2560px){
  #content-l {
    width: 100%;
    height: 280px;
  }
  #content1r {
    width: 45%;
    height: 280px;
  }
  .lgin {
    /* margin-left: 8.2%; */
    text-align: left;
  } 
  /* .lgin1r {
    padding-left: 25.5%;
  }  */
  .lef1 {
    margin-left: 605px;
  }
  .lef2 {
    float: right;
  }
}

ul li {
  list-style-position: outside;
}

.logout-button{
  color: #0bb0b6;
  background: #fff;
  border: 1px solid #0bb0b6;
  margin-right: 15px;
}

.logout-button:hover{
  background: #0bb0b6;
  color: #fff;
}


@font-face {
    font-family: 'WebFonts';
    src: url(/static/media/AlegreyaSans-Medium.7b2f33e7.woff);
  }



.viewordertemp {
    position: relative;
    /* background: #6a1414;
    border: 0px solid #6a1414; */
    padding-top: 0px;
    padding-bottom: 0px;
    /* width: 594px;
    height: 792px; */

    /* width: 618px;
    height: 816px; */

    /* width: 620.26px;
    height: 818.26px; */

    width: 646.26px;
    height: 844.26px;
    

}

.size {
    font-size: 14.45px;
}

.icon {
    width: 80px;
    float: none;
    cursor: pointer;
    margin-left: 130px;
    margin-top: 30px;
}

.icon1 {
    width: 80px;
    float: none;
    cursor: pointer;
    margin-left: 10px;
    margin-top: -180px;
}

.add {
    margin-left: 50px;
}

.add1 {
    border: solid 2px #e3e3e3;
    color: #555;
    height: 40px;
    background: none;
    border-radius: 2px;
    box-shadow: none;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
    padding: 8px;
}

.up1 {
    margin-left: 10px;
    margin-top: 15px;
    margin-right: 40px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
}

.para {
    margin-right: 8px;
    word-break: break-all;
    font-size: 13.5px;
    margin-bottom: 15px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    cursor: pointer;
}

.tem1 {
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    left: 43px;
}

.tem2 {
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    right: 326px;
}

.tem3 {
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    left: 328px;
}

.tem4 {
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    left: 470px;
}

.tem6 {
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 398px;
    bottom: 316px;
}

.tem7 {
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 398px;
    top: 536px;
}

.tem8 {
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 398px;
    top: 674px;
}

.tem5 {
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 398px;
    top: 260px;
}

.iqama {
    width: 320px;
    height: 454px;
    top: 300px;
    left: 41px;
    position: absolute;
}

.time {
    width: 346px;
    position: absolute;
    top: 260px;
    left: 41px;
    line-height: 0px;
}

.style {
    display: block;
    background: none;
    color: #D21A1A;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.just {
    margin-top: -1px;
}

.bk {
    color: white;
    width: 30px;
    margin-left: 10px;
    margin-bottom: 20px;
    background: grey;
    height: 40px;
    font-size: 14px;
    border-radius: 2px;
    font-weight: "bolder",
}

.bk:hover {
    background-color: #f05a5a;
}

.h2 {
    margin: 50px 0 30px 0;
    font-size: 30px;
    display: block;
}

.ol {
    margin: 0 0 50px 20px;
    font: 17px Roboto, Arial, sans-serif;
    color: black;
}

.fil {
    height: 5px;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

.inst {
    font-size: 18px;
    font-family: Roboto, Arial, sans-serif;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 13px;
    display: block;
}

.are {
    border: solid 2px #e3e3e3;
    color: #777;
    height: 150px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
    padding: 8px;
    width: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-height: 40px;
}

.dp {
    position: relative;
    background-color: #f7f7f7;
    padding: 10px 10px 15px 10px;
    width: 500px;
    height: 50px;
    border-radius: 3px;
    align-items: center;
    border: solid 1px #e1e1e1;
    background-color: transparent;
    margin: 1px;
    -webkit-box-align: center;
    display: flex;
}

.fil1 {
    height: 40px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    font-weight: normal;
    width: auto;
    border-color: #e1e1e1;
    border-style: solid;
    border-width: 0;
    zoom: 1;
}

.but {
    color: white;
    width: 30px;
    margin-left: 410px;
    margin-bottom: 20px;
    background-color: #D21A1A;
    padding: 10px 80px 10px 15px;
    font-size: 14px;
    border-radius: 2px;
    margin-top: 20px;
}

.but:hover {
    background-color: #dd4b4b;
}

.up2 {
    margin-top: 15px;
    margin-left: -30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
}

.radios1 input[type=radio]:checked+img {
    outline: 68px solid rgba(210, 26, 26, 0.6);
    outline-offset: -68px;
    overflow: hidden;
    position: absolute;
}

.radios1 input[type=radio] {
    display: none;
}

input[disabled] {
    cursor: not-allowed;
}

input[type="radio"][disabled] {
    background-color: transparent;
}

input[type="radio"] {
    width: auto;
    line-height: normal;
}

[type=radio] {
    position: absolute !important;
    opacity: 0 !important;
    height: 0 !important;
}

/* .iqama-table tr th:first-child,
.iqama-table tr td:first-child {
    background-color: #b2d0da;
}

.iqama-table tr th:nth-child(3),
.iqama-table tr td:nth-child(3) {
    background-color: #b2d0da;
}

.iqama-table tr th:nth-child(4),
.iqama-table tr td:nth-child(4) {
    background-color: #b2d0da;
    min-width: 80px !important;
    width: 80px;
}

.iqama-table tr th:nth-child(5),
.iqama-table tr td:nth-child(5) {
    background-color: #b2d0da;
}

.iqama-table tr th:nth-child(7),
.iqama-table tr td:nth-child(7) {
    background-color: #b2d0da;
}

.iqama-table tr th:nth-child(2),
.iqama-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqama-table tr th:nth-child(6),
.iqama-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}


/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */

.head-size {
    font-size: 6px;
}

.iqama-table th,
.iqama-table td {
    line-height: 0px;
    border: 1px solid #dddddd;
    padding: 7.9px;
    font-family: Arial;
    font-weight: 700;
}

.iqama1{
    width: 346px;
    height: 478px;
    top: 80px;
    left: 15px;
    position: absolute;
}

.time1{
    width: 346px;
    height: 52px;
    position: absolute;
    top: 43px;
    left: 41px;
}

.temph1{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    top: 43px;
    right: 42px;
}

.temph2{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    top: 180px;
    right: 42px;
}

.temph3{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    top: 317px;
    right: 42px;
}

.temph4{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    top: 453px;
    right: 42px;
}

.temph5{
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 594px;
    left: 470px;
}

.temph6{
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 594px;
    left: 328px;
}

.temph7{
    width: 134px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 326px;
}

.temph8{
    width: 137px;
    height: 206px;
    cursor: pointer;
    position: absolute;
    top: 594px;
    left: 40px;
}

.iqama1-table tr th:first-child,
.iqama1-table tr td:first-child {
    background-color: #b2d0da;
}

.iqama1-table tr th:nth-child(3),
.iqama1-table tr td:nth-child(3) {
    background-color: #b2d0da;
}

.iqama1-table tr th:nth-child(4),
.iqama1-table tr td:nth-child(4) {
    background-color: #b2d0da;
    min-width: 80px !important;
    width: 80px;
}

.iqama1-table tr th:nth-child(5),
.iqama1-table tr td:nth-child(5) {
    background-color: #b2d0da;
}

.iqama1-table tr th:nth-child(7),
.iqama1-table tr td:nth-child(7) {
    background-color: #b2d0da;
}

.iqama1-table tr th:nth-child(2),
.iqama1-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqama1-table tr th:nth-child(6),
.iqama1-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
}

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqama1-table th,
.iqama1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    font-family: Arial;
    font-weight: 700;
}

.head-size{
    font-size: 6px;
    
}


.iqama2{
    width: 346px;
    height: 478px;
    top: 300px;
    left: 233px;
    position: absolute;
}

.time2{
    width: 346px;
    height: 55px;
    position: absolute;
    top: 260px;
    left: 259px;
}

.newtime{
    width: 346px;
    height: 55px;
    position: absolute;
    top: 260px;
    left: 41px;
}

.temv5{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 260px;
}

.temv6{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    bottom: 316px;
}

.temv7{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 536px;
}

.temv8{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 674px;
}

/* .iqama2-table tr th:first-child,
.iqama2-table tr td:first-child {
    background-color: #b2d0da;
}

.iqama2-table tr th:nth-child(3),
.iqama2-table tr td:nth-child(3) {
    background-color: #b2d0da;
}

.iqama2-table tr th:nth-child(4),
.iqama2-table tr td:nth-child(4) {
    background-color: #b2d0da;
    min-width: 80px !important;
    width: 80px;
}

.iqama2-table tr th:nth-child(5),
.iqama2-table tr td:nth-child(5) {
    background-color: #b2d0da;
}

.iqama2-table tr th:nth-child(7),
.iqama2-table tr td:nth-child(7) {
    background-color: #b2d0da;
}

.iqama2-table tr th:nth-child(2),
.iqama2-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqama2-table tr th:nth-child(6),
.iqama2-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqama2-table th,
.iqama2-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    font-family: Arial;
    font-weight: 700;
}

.head-size{
    font-size: 6px;
    
}


.iqamak1{
    width: 346px;
    height: 478px;
    top: 80px;
    left: 259px;
    position: absolute;
}

.timek1{
    width: 346px;
    height: 52px;
    position: absolute;
    top: 43px;
    left: 259px;
}

.temk1{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 43px;
}

.temk2{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 180px;
}

.temk3{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    left: 44px;
    top: 317px;
}

.temk4{
    width: 206px;
    height: 130px;
    cursor: pointer;
    position: absolute;
    
    left: 44px;
    top: 453px;
}

/* .iqamak1-table tr th:first-child,
.iqamak1-table tr td:first-child {
    background-color: #b2d0da;
}

.iqamak1-table tr th:nth-child(3),
.iqamak1-table tr td:nth-child(3) {
    background-color: #b2d0da;
}

.iqamak1-table tr th:nth-child(4),
.iqamak1-table tr td:nth-child(4) {
    background-color: #b2d0da;
    min-width: 80px !important;
    width: 80px;
}

.iqamak1-table tr th:nth-child(5),
.iqamak1-table tr td:nth-child(5) {
    background-color: #b2d0da;
}

.iqamak1-table tr th:nth-child(7),
.iqamak1-table tr td:nth-child(7) {
    background-color: #b2d0da;
}

.iqamak1-table tr th:nth-child(2),
.iqamak1-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqamak1-table tr th:nth-child(6),
.iqamak1-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqamak1-table th,
.iqamak1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    font-family: Arial;
    font-weight: 700;
}

.head-size{
    font-size: 6px;
    background: #f5f5dc;
}

.ra-head-size {
    background-color: #fdfdf1;
}

/* virtual css */

.trow {
    width: 100%;
    display: flex;
    text-align: center;
}

.tcol {
    display:inline-block;
    width: 100%;
    border: 1px solid #b4b3b3;
    background: white;
    /* background: #f5f5dc; */
    padding-top: 3px;
}

.fajriqama-col {
    background: #FFE6EE;
    min-width: 60px !important;
    width: 60px;
}

.ra-fajriqama-col {
    min-width: 60px !important;
    width: 60px;
}


.maghribiqama-col {
    background: #e4f2db;
    min-width: 82px !important;
    width: 82px;
}

.ra-maghribiqama-col {
    min-width: 82px !important;
    width: 82px;
}

.dhuhr-col {
    min-width: 125px !important;
    width: 125px;
}

.asr-col {
    min-width: 60px !important;
    width: 60px;
}

.isha-col {
    min-width: 69.18px !important;
    width: 69.18px;
}

.date-col {
    min-width: 40px !important;
    width: 40px;
}

.day-col {
    min-width: 40px !important;
    width: 40px;
}

.sunrise-col {
    min-width: 40px !important;
    width: 40px;
}

.template-hide {
    height: 1px;
    overflow: hidden;
    opacity: 0;
}
  @media print {
    .template-hide {
      display: block;
      opacity: 1;
      overflow: visible;
      height: auto;
    }
  }

/*PRINT CSS*/
/* @media print {
    .temp {
        width: 15.7cm;
        height: 29.7cm;
        margin: 30mm 45mm 30mm 45mm;
    }
    .noprint {display:none;}
}

@page {
    size: A4;
    margin: 0;
  } */


  
  @media print  {
    @page {
      orientation: portrait;

    }
    body * {
      visibility: hidden;
    }
    .section-to-print, .section-to-print * {
      visibility: visible;
    }
    .section-to-print {
      position: absolute;
      
    }
  }



  .danger {
    background-color: #E5343D;
    border-color: #BF2D35;
    color: #fff;
    border-radius: 2px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.8;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-image: none;
    border: 1px solid transparent;
    margin-top: 28px;
   
  }

  @media only screen and (max-width: 1440px) {
      .vieworder-scroll {
          overflow-x: scroll;
      }
  }

  @media only screen and (max-width: 1024px) {
    .vieworder-flex {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
  }

  .vieworder-img-active + img {
    outline: 68px solid rgba(210, 26, 26, 0.6);
    outline-offset: -68px;
    overflow: hidden;
    position: absolute;
  }


.fa-col {
    background: #FFE6EE;
    min-width: 41px !important;
    width: 41px;
}

.ra-fa-col {
    min-width: 41px !important;
    width: 41px;
}

.mag-col {
    background: #e4f2db;
    min-width: 50px !important;
    width: 50px;
}

.dhu-col {
    min-width: 70px !important;
    width: 70px;
}

.as-col {
    min-width: 45px !important;
    width: 45px;
}

.ish-col {
    min-width: 45px !important;
    width: 45px;
}

.dat-col {
    min-width: 30px !important;
    width: 30px;
}

.da-col {
    min-width: 30px !important;
    width: 30px;
}

.sun-col {
    min-width: 30px !important;
    width: 30px;
}

/* Lunar calendar css */

/* .tcoll {
    display:inline-block;
    border: 1px solid #b4b3b3;
    background: #f5f5dc;
    padding-top: 3px;
} */

.tcoll {
    display:inline-block;
    /* width: 100%; */
    border: 1px solid #b4b3b3;
    /* background: white; */
    background: #fdfdf1;
    padding-top: 3px;
}

#fr-tcoll {
    display:inline-block;
    /* width: 100%; */
    border: 1px solid #b4b3b3;
    background: #d2d1d0;
    padding-top: 3px;
    display: flex;
}

#n-fr-tcoll {
    background: #d2d1d0;
}

.ra-tcoll {
    display:inline-block;
    /* width: 100%; */
    border: 1px solid #b4b3b3;
    /* background: #f5f5dc; */
    padding-top: 3px;
}

.dhuhrr-col {
    min-width: 72px !important;
    width: 72px;
}

.datee-col {
    min-width: 40px !important;
    width: 40px;
}

.dayy-col {
    min-width: 31px !important;
    width: 31px;
}

.sunrisee-col {
    min-width: 33px !important;
    width: 33px;
}

.magg-col {
    background: #e4f2db;
    min-width: 46.6px !important;
    width: 46.6px;
}

.ra-magg-col {
    min-width: 46.6px !important;
    width: 46.6px;
}

.dhuu-col {
    min-width: 50px !important;
    width: 50px;
}

.ass-col {
    min-width: 39px !important;
    width: 39px;
}

.daa-col {
    min-width: 21px !important;
    width: 21px;
}

/*Lunar month css*/
.lun-col {
    min-width: 42px !important;
    width: 42px;
}

.lunar-col {
    min-width: 60px !important;
    width: 60px;
}

/*Ramadan month css*/
.ramadan-col {
    /* display: inline-block; */
    min-width: 21px !important;
    width: 21px;
}

.ramadaan-col {
    min-width: 30px !important;
    width: 30px;
}

.center-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1.5px;
}
@font-face {
  font-family: 'WebFonts';
  src: url(/static/media/AlegreyaSans-Medium.7b2f33e7.woff);
}

@font-face {
  font-family: 'WebFonts2';
  src: url(/static/media/AlegreyaSans-Medium.5a3f02a7.ttf);
}

.nav-menuu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 30px;
  justify-content: center;
  background-color: transparent;
}
.addiv1 {
    height: 60px;
    /* border: solid 1px #D21A1A; */
    padding-top: 9px;
    }

.anc1 {
    text-decoration: none;
    color: inherit;
}
.anc2 {
      padding: 8px 20px;
      border-radius: 4px;
      outline: none;
      border: none;
      font-size: 18px;
      color: white;
      cursor: pointer;
      background-color: #D21A1A;
      text-decoration: none;
    }

    .hov:hover{
        color: #558B2F;
        
    }
    
    .content:hover {background-color: #f8fafc !important; zoom: 1.1;}

    .hv1:hover{
      border-bottom:solid 1px green;
    }

    .sub-header-menu p {
      margin-bottom: 0px;
  }
  
  .sub-header-menu .dropdown{
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
  }
  
  .sub-header-menu .dropdown:hover{   
      background: #fff9;
  }

  .sub-header-menu{
    background: #eef2f6;
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
    z-index: 1;
    position: relative;
}


    /* .masjidheader {
      margin-left: 105px;
      font-family: 'Alegreya Sans Medium';
      margin-bottom: 0px;
    }
    .masjidheader1 {
      background-color: white;
      padding-left: 6%;
    } */
.masjidheader {
    /* margin-left: 105px; */
    font-family: 'Alegreya Sans Medium';
    margin-bottom: 0px;
  }
  .masjidheader1 {
    background-color: white;
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
    z-index: 10;
    position: relative;
    /* padding-left: 6%; */
  }
  .headad2 {
    height: 60px;
    border-right: none;
    border-top: none;
    padding-left: 65px;
    padding-right: 50px;
    /* background-color: #EEF2F6; */
}

@media only screen and (min-width: 2560px) {
    .headad2 {
        padding-left: 225px;
        padding-right: 200px;
    }
    /* .masjidheader1 {
        padding-left: 12.5%;
    } */
}


.header {
  background-color: #fff;
}

.navbar-links li {
  display: inline-block;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding-left: 0px;
  padding-right: 0px;
}

#home-icon {
  color: #374767;
  padding: 5px;
  font-size: 18px;
  border: solid 1px #ddd;
}

#logout-icon {
  color: #374767;
  padding: 5px;
  font-size: 18px;
  border: solid 1px #ddd;
  cursor: pointer;
}

.nav-item {
  height: 0px;
}

ul.masjidheader > .dropdown {
  padding: 10px 12px !important;
}

ul.masjidheader > .dropdown a p {
  margin-bottom: 0px;
}
@font-face {
    font-family: 'WebFonts';
    src: url(/static/media/AlegreyaSans-Medium.7b2f33e7.woff);
  }

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* body {
 font-family: Roboto,Arial,sans-serif;
 font-size: 14px;

} */
.wrapper {
    background-color: #fff;
    width: 70%;
    padding: 15px 16px 0px 16px;
    /* margin:  auto; */
    margin-left: 260px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    
}
.wrapper h2{
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 30px;
    text-align: center;
}
.input-name{
    width: 100%;
    position: relative;
    margin: 20px auto;
    
}
.lock{
    padding:8px 11px;
}

.name{
    width:100%;
    padding:8px 40px 8px 10px;
    border: 1px solid #cccccc;
    color: gray;
    transition: all 0.30s ease-in-out;
    
}
.input-name span{
    margin-left: 35px;
}
.text-name{
    width: 100%;
    padding:8px 0 8px 40px;
}
.input-name i{
    position: absolute;
    font-size: 18px;
    border-right: 1px solid #cccccc;
}
.config{
    width: 100;
    position: relative;
    margin: 20px auto;
    border-top:1px solid turquoise;
    border-bottom:1px solid turquoise;
    padding-top: 10px;
    padding-bottom: 10px;
}
.subhead{
    font-size: 25px;
}
.com1{
        width: 100%;
        padding:8px 0 8px 40px;
}


.email{
    padding: 8px;
}
.text-name1{
    width: 100%;
    padding:8px 0 8px 40px;
}
.text-name2{
    width: 100%;
    padding:8px 0 8px 40px;
}

/* .button1{
    height: 38px;
    width: 75px;
    background-color:#D21A1A;
    color: white;
    cursor: pointer;
} */

/* .button2{
    height: 37px;
    width: 150px;
    background-color: #D21A1A;
    color: white;
    cursor: pointer;
} */
.mapw{
    
    height: 50%;
    
   
}
.text-name, .name{
    border: 1px solid #cccccc;
    outline: none;
    transition: all 0.30s ease-in-out;
}
.text-name:hover, .name:hover{
    background-color: #fafafa;
}
.text-name:focus, .name:focus{
    box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
      border: 1px solid #f5ba1a;
      background: #fafafa;
}
.radio-button{
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
 .gender {
    margin-right: 1em;
}
.gender:before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    border-radius: 100%;
    vertical-align: -3px;
    border: 2px solid #cccccc;
    padding: 0.15em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
  }

  .radio-button:hover + .gender:before {
    border-color: #000;
  }
  .radio-button:checked + .gender:before {
    background-color:#000;
    border-color: #000;
  }

  .country{
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: #7b7b7b;
    border: 1px solid #cccccc;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
}
.country::-ms-expand{
    display: none;
}
.country:hover, .country:focus{
    color: #000;
    background: #fafafa;
    border-color: #000;
    outline: none;
}
.arrow {
    position: absolute;
    top: calc(50% - 4px);
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-width: 8px 5px 0 5px;
    border-style: solid;
    border-color: #7b7b7b transparent transparent transparent;
 }
  
.country:hover +.arrow, .country:focus +.arrow{
      border-top-color: #000;
}
.chack{
    margin-right: 1em;
    position: relative;
}
.chack::before{
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    vertical-align: -2px;
    border: 2px solid #cccccc;
    padding: 0.12em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
}
.chack::after{
    border-right: 2px solid #000;
          border-top: 2px solid #0000;
          content: "";
          height: 20px;
          left: 2px;
          position: absolute;
          top: 7px;
          -webkit-transform: scaleX(-1) rotate(135deg);
                  transform: scaleX(-1) rotate(135deg);
          -webkit-transform-origin: left top;
                  transform-origin: left top;
          width: 7px;
          display: none;
}
.chack-button{
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.chack-button:hover +.chack::before{
    border-color: #000;
}
.chack-button:checked +.chack::before{
    border-color: #000;
}
.chack-button:checked +.chack::after{
    -webkit-animation: check 0.8s ease 0s running;
    animation: check 0.8s ease 0s running;
        display: block;
        width: 7px;
        height: 20px;
        border-color: #000;
}
@-webkit-keyframes check {
    0% { height: 0; width: 0; }
    25% { height: 0; width: 7px; }
    50% { height: 20px; width: 7px; }
  }
  
  @keyframes check {
    0% { height: 0; width: 0; }
    25% { height: 0; width: 7px; }
    50% { height: 20px; width: 7px; }
  }
  .button{
    background: #D21A1A;
    height: 35px;
    line-height: 35px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    border-radius: 5px;
    border:solid 1px  black;
    
  }
  .button:hover{
      background-color: green;
  }
  .button:hover{
      background-color: green;
  }



  @media (max-width: 480px) {
      .wrapper{
          width: 100%;
      }
      .input-name{
          margin-bottom: -10px;
      }
      .name{
          width: 100%;
          padding:8px 0 8px 40px;
          margin-bottom: 10px;
      }
      .input-name span{
          padding: 0;
          margin:0;
      }
  }
  .bodyy{
    background: linear-gradient(180deg, rgb(255 255 255 / 80%) 10%, rgb(255 255 255 / 80%) 80% ),url(/static/media/masjidImage1.deb79120.jpg) no-repeat center center;
    background-size: cover;
    height:100%;
    padding-top: 15px;
    padding-bottom: 30px;
    background-attachment: fixed;
    min-height: calc(100vh - 130px)!important;
}
.legend{
    font-size: 13px;
        font-weight: bolder !important;
        text-align: left !important;
        width:auto;
        padding:0 10px;
        border-bottom:none;
        
   
  }
  
.fieldset{
    border: 1px solid #dde2e7 !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    box-shadow:  0px 0px 0px 0px #000;
  }
  .label{
    cursor: text;
    font-family:Helvetica; 
    font-size: 13px;
    color: #374767;
    font-weight: lighter;
  }
.input{
    height: 30px;
    border-radius: 2px;
}
.select{
    height: 30px;
    border-radius: 2px; 
    padding: 5px 0px 5px 0px;
    font-family: Arial;
    font-weight: lighter;
    padding-top:5px;
    padding-bottom:5px;
}
.busi-order {
    padding-left: 93px;
    background-color: white;
}
.busi-tab {
    background-color: white;
    height: auto;
    position: relative;
    padding-bottom: 20px;
    /* width: calc(100% - 18px); */
}
.busi-tabb {
    /* margin-left: 200px; */
    background-color: white;
    /* width: 71.5%; */
    height: auto;
    position: relative;
    padding-bottom: 20px;
}

.busi-tab, .busi-tabb{
    background: #fff;
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
}
.mos-profile {
    background-color: white;
    width: 107%;
    height: auto;
}
/* @media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
    .busi-order {
        padding-left: 0px;
        background-color: white;
    }
    .busi-tab {
        margin-left: 105px;
    }
    .mos-profile {
        margin-right: 100px;
    }
    .busihead {
        padding-left: 95px;
    }
    .busi-tab1 {
        margin-left: 73px;
    }
} */
@media only screen and (min-width:321px) and (max-width:768px){
    .busi-order {
        padding-left: 100px;
    }
    /* .busi-tab {
        margin-left: 80px;
    } */
}
  @media only screen and (min-width:769px) and (max-width:1260px){
    .busi-order {
        padding-left: 400px;
    }
    /* .busi-tab {
        margin-left: 78px;
    } */
}
@media only screen and (max-width: 1440px) {
    .busi-order {
        padding-left: 400px;
    }
    /* .busi-tab {
        margin-left: 70px;
    } */
}
  @media only screen and (min-width: 2560px){
    .mos-profile {
        margin-left: 450px;
        width: 117%;
    }
    .busi-order {
        padding-left: 400px;
    }
    /* .busi-tab {
        margin-left: 580px;
        width: 58.5%;
    } */
}

.newmosque-cont {
    background: white;
    /* width: 72%;
    margin-left: 200px; */
}

.batch-cont {
    background: white;
    width: auto;
    /* margin-left: 200px; */
    /* padding: 13px; */
  }

  /* .defaultimg{
    background: url('../../images/logo1.jpg');
    background-size: cover;
    width: 150px;
    height: 130px;
  } */

  /* @media only screen and (max-width: 486px) {
      .business-head {
        display: flex;
        flex-direction: column;
    }
  }  */


  .admin-tab {
    background-color: white;
    height: auto;
    position: relative;
    padding-bottom: 20px;
    /* width: calc(100% - 18px); */
  }

  /* @media only screen and (max-width: 486px) {
    .admin-head {
      display: flex;
      flex-direction: column;
  }
}  */


.template-images-mosque {
    /* width: '130px'
     height: '150px', 
     cursor: 'pointer', */
     width: 100%;
     height: auto;
     cursor: pointer;
}

.template-image-wrapper-mosque {
    width: 25%;
    padding: 10px 10px 10px 0px;
}

.mosque-temp-width {
    width: 85%;
    /* height: 100%; */
}


@media only screen and (max-width: 486px) {
    .mosque-table {
      width: 100%;
      overflow-x: scroll;
    }
    
    .mosque-table table {
      min-width: 800px;
    }
  }

  .header-image-wrapper {
    overflow-x: scroll;
    width: 100%;
}


.calendar-header {
    background-image: url(/static/media/calendar_header.4693479c.jpg);
    background-size: cover;
}

.main-card{
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
}

.btn-primary{
    background-color: #0bb0b6;
    border-color: #0bb0b6;
}


.btn-primary:hover {
    color: #0bb0b6!important;
    background-color: #fff;
    border-color: #0bb0b6;
}

.btn-secondary {
    background-color: #fff;
    border-color: #0bb0b6;
    color: #0bb0b6;
}

.btn-secondary:hover {
    color: #fff!important;
    background-color: #0bb0b6;
    border-color: #fff;
}

.legend {
    font-size: 18px;
    margin: 10px auto;
    font-weight: 500!important;
    color: #0bb0b6;
  }
@font-face {
    font-family: 'WebFonts';
    src: url(/static/media/AlegreyaSans-Medium.7b2f33e7.woff);
  }



.radios2 input[type=radio]:checked+img {
    outline: 58px solid rgba(210, 26, 26, 0.6);
    outline-offset: -58px;
    overflow: hidden;
    position: absolute;
}

.vieworder2-img-active + img {
    outline: 58px solid rgba(210, 26, 26, 0.6);
    outline-offset: -58px;
    overflow: hidden;
    position: absolute;
  }


.radios2 input[type=radio] {
    display: none;
}

input[disabled] {
    cursor: not-allowed;
}

input[type="radio"][disabled] {
    background-color: transparent;
}

input[type="radio"] {
    width: auto;
    line-height: normal;
}

[type=radio] {
    position: absolute !important;
    opacity: 0 !important;
    height: 0 !important;
}



.iqamay1{
    width: 346px;
    height: 478px;
    top: 300px;
    left: 18px;
    position: absolute;
    
}

.timey1{
    width: 346px;
    height: 58px;
    position: absolute;
    top: 258px;
    left: 44px;
    
}

.temy1{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 41px;
    padding: 4px;
}

.temy2{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 152px;
    padding: 4px;
}

.temy3{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 263px;
    padding: 4px;
}

.temy4{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 374px;
    padding: 4px;
}

.temy5{
    /* width: 115px;
    height: 210px; */
    width: 1.22in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 485px;
    padding: 4px;
}

.temy6{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;  
    left: 394px;
    top: 253px;
    padding: 5px;
}

.temy7{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 394px;
    top: 363px;
    padding: 5px;
}

.temy8{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 394px;
    top: 473px;
    padding: 5px;
}

.temy9{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;  
    left: 394px;
    top: 583px;
    padding: 5px;
}

.temy10{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;  
    left: 394px;
    top: 693px;
    padding: 5px;
}

/* .iqamay1-table tr th:first-child,
.iqamay1-table tr td:first-child {
    background-color: #f5f5dc;
}

.iqamay1-table tr th:nth-child(3),
.iqamay1-table tr td:nth-child(3) {
    background-color: #f5f5dc;
}

.iqamay1-table tr th:nth-child(4),
.iqamay1-table tr td:nth-child(4) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 80px;
}

.iqamay1-table tr th:nth-child(5),
.iqamay1-table tr td:nth-child(5) {
    background-color: #f5f5dc;
}

.iqamay1-table tr th:nth-child(7),
.iqamay1-table tr td:nth-child(7) {
    background-color: #f5f5dc;
}

.iqamay1-table tr th:nth-child(2),
.iqamay1-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqamay1-table tr th:nth-child(6),
.iqamay1-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */

.head-size{
    font-size: 6px;
    background: #f5f5dc;
}
.iqamay1-table th,
.iqamay1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    /* padding: 7.9px;
    padding-left: 0px; */
}



/* template6 css */
.iqamag1{
    width: 346px;
    height: 478px;
    top: 80px;
    left: 18px;
    position: absolute;
}

.timeg1{
    width: 346px;
    height: 55px;
    position: absolute;
    top: 43px;
    left: 44px;
}

.temg1{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 40px;
    padding: 6px;
}

.temg2{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 146px;
    right: 40px;
    padding: 6px;
}

.temg3{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 256px;
    right: 40px;
    padding: 6px;
}

.temg4{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 365px;
    right: 40px;
    padding: 6px;
}

.temg5{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 474px;
    right: 40px;
    padding: 6px;
}

.temg6{
    /* width: 110px;
    height: 210px; */
    width: 1.04in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 45px;
    padding: 3px;
}

.temg7{
    /* width: 115px;
    height: 210px; */
    width: 1.16in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 148px;
    padding:3px;
}

.temg8{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 262px;
    padding: 3px;
}

.temg9{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 377px;
    padding: 3px;
}

.temg10{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    left: 42px;
    padding: 3px;
}

/* .iqamag1-table tr th:first-child,
.iqamag1-table tr td:first-child {
    background-color: #f5f5dc;
}

.iqamag1-table tr th:nth-child(3),
.iqamag1-table tr td:nth-child(3) {
    background-color: #f5f5dc;
}

.iqamag1-table tr th:nth-child(4),
.iqamag1-table tr td:nth-child(4) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 80px;
}

.iqamag1-table tr th:nth-child(5),
.iqamag1-table tr td:nth-child(5) {
    background-color: #f5f5dc;
}

.iqamag1-table tr th:nth-child(7),
.iqamag1-table tr td:nth-child(7) {
    background-color: #f5f5dc;
}

.iqamag1-table tr th:nth-child(2),
.iqamag1-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqamag1-table tr th:nth-child(6),
.iqamag1-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqamag1-table th,
.iqamag1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    padding-left: 0px;
}

.head-size{
    font-size: 6px;
    
}

/* Template7 css */
.iqamau2{
    width: 346px;
    height: 478px;
    top: 300px;
    left: 233px;
    position: absolute;
}

.timeu2{
    width: 346px;
    height: 58px;
    position: absolute;
    top: 258px;
    left: 253px;
}
.temu6{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 253px;
    padding: 5px;
}

.temu7{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 363px;
    padding: 5px;
}

.temu8{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 473px;
    padding: 5px;
}

.temu9{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    /* top: 5px; */
    left: 40px;
    top: 583px;
    padding: 5px;
}
.temu10{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 693px;
    padding: 5px;
}

/* .iqamau2-table tr th:first-child,
.iqamau2-table tr td:first-child {
    background-color: #f5f5dc;
}

.iqamau2-table tr th:nth-child(3),
.iqamau2-table tr td:nth-child(3) {
    background-color: #f5f5dc;
}

.iqamau2-table tr th:nth-child(4),
.iqamau2-table tr td:nth-child(4) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 80px;
}

.iqamau2-table tr th:nth-child(5),
.iqamau2-table tr td:nth-child(5) {
    background-color: #f5f5dc;
}

.iqamau2-table tr th:nth-child(7),
.iqamau2-table tr td:nth-child(7) {
    background-color: #f5f5dc;
}

.iqamau2-table tr th:nth-child(2),
.iqamau2-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqamau2-table tr th:nth-child(6),
.iqamau2-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqamau2-table th,
.iqamau2-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    padding-left: 0px;
}

.head-size{
    font-size: 6px;
    
}

/* template8 css */
.iqamaz1{
    width: 346px;
    height: 478px;
    top: 80px;
    left: 233px;
    position: absolute;
}

.timez1{
    width: 346px;
    height: 55px;
    position: absolute;
    top: 43px;
    left: 259px;
}

.temz1{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 36px;
    padding: 6px;
}

.temz2{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 146px;
    padding: 6px;
}

.temz3{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 256px;
    padding: 6px;
}

.temz4{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 365px;
    padding: 6px;
}

.temz5{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 474px;
    padding: 6px;
}



/* .iqamaz1-table tr th:first-child,
.iqamaz1-table tr td:first-child {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 30px;
}

.iqamaz1-table tr th:nth-child(2),
.iqamaz1-table tr td:nth-child(2) {
    background-color:#f5f5dc;
    min-width: 80px !important;
    width: 25px;
}


.iqamaz1-table tr th:nth-child(4),
.iqamaz1-table tr td:nth-child(4) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 30px;
}

.iqamaz1-table tr th:nth-child(5),
.iqamaz1-table tr td:nth-child(5) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 70px;
}

.iqamaz1-table tr th:nth-child(6),
.iqamaz1-table tr td:nth-child(6) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 43px;
}

.iqamaz1-table tr th:nth-child(8),
.iqamaz1-table tr td:nth-child(8) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 43px;
}

.iqamaz1-table tr th:nth-child(3),
.iqamaz1-table tr td:nth-child(3) {
    background-color:  #FFE6EE;
    min-width: 80px !important;
    width: 43px;
}

.iqamaz1-table tr th:nth-child(7),
.iqamaz1-table tr td:nth-child(7) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqamaz1-table th,
.iqamaz1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    /* padding-left: 0px; */
    font-family: Arial;
    font-weight: 700;
}

.head-size{
    font-size: 6px;
    
}


.danger {
    background-color: #E5343D;
    border-color: #BF2D35;
    color: #fff;
    border-radius: 2px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.8;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-image: none;
    border: 1px solid transparent;
    margin-top: 28px;
    /* margin-left: 10px; */
  }

  @media only screen and (max-width: 1440px) {
    .vieworder-scroll {
        overflow-x: scroll;
    }
}

@media only screen and (max-width: 1024px) {
  .vieworder-flex {
      display: flex;
      flex-direction: column;
      width: 100%;
  }
}


/* Virtual template */
.trow {
    width: 100%;
    display: flex;
    text-align: center;
}

.tcol {
    display:inline-block;
    width: 100%;
    border: 1px solid #b4b3b3;
    /* background: #f5f5dc; */
    background: white;
    padding-top: 3px;
}

.fajriqama-col {
    background: #FFE6EE;
    min-width: 60px !important;
    width: 60px;
}

.maghribiqama-col {
    background: #e4f2db;
    min-width: 85px !important;
    width: 85px;
}

.dhuhr-col {
    min-width: 116px !important;
    width: 116px;
}

.asr-col {
    min-width: 60px !important;
    width: 60px;
}

.isha-col {
    /* min-width: 78.18px !important;
    width: 78.18px; */
    min-width: 76px !important;
    width: 76px;
}

.date-col {
    min-width: 40px !important;
    width: 40px;
}

.day-col {
    min-width: 40px !important;
    width: 40px;
}

.sunrise-col {
    min-width: 40px !important;
    width: 40px;
}

.template-hide {
    height: 1px;
    overflow: hidden;
    opacity: 0;
}
  @media print {
    .template-hide {
      display: block;
      opacity: 1;
      overflow: visible;
      height: auto;
    }
  }

  .fa-col {
    background: #FFE6EE;
    min-width: 41px !important;
    width: 41px;
}

.mag-col {
    background: #e4f2db;
    min-width: 50px !important;
    width: 50px;
}

.dhu-col {
    min-width: 70px !important;
    width: 70px;
}

.as-col {
    min-width: 45px !important;
    width: 45px;
}

.ish-col {
    min-width: 45px !important;
    width: 45px;
}

.dat-col {
    min-width: 30px !important;
    width: 30px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-radius: 1rem;
}
td, th {
  border: 1px solid #dddddd;
  /* text-align: left; */
  padding: 8px;
}

tr:nth-child(even) {
  /* background-color: #dddddd; */
  border-collapse: collapse;
}

button {
    width: auto;
    height: auto;
}

#center {
  margin: auto;
  margin-top: 3rem;
  width: 80%;
  border: solid 1px white;
  padding: 60px;
  background-color: white;
  border-radius: 1rem;
}

.mosque-table {
  width: 100%;
  overflow-x: scroll;
}

.batch-table {
  width: 100%;
  overflow: scroll;
}
table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  /* text-align: left; */
  padding: 8px;
}

tr:nth-child(even) {
  /* background-color: #EEF2F6; */
  border-collapse: collapse;
}

#align{
  margin: auto;
  margin-top: 3rem;
  width: 80%;
  border: solid 1px white;
  padding:60px;
  background-color: white;
  border-radius: 1mm  ;

}

/* body{
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
} */
select{
    font-weight: bolder;
    
    color:#333;
   
    width:75%;
    min-width:100%;
    padding:10px 12px;
    
}
option{
    color:#333;
}

.bdyy{
    background-image: url(/static/media/mountain1.de01e722.jpeg);
    height:100%;
    padding-top: 10px;
    padding-bottom: 30px;
}
label{
    
    font-size: 13px;
    max-width: 100%;
    font-weight: 700;
    margin-bottom: 5px
}


input[type=time]::-webkit-datetime-edit-ampm-field{
    display: none;
  }

  input[type=time]::-webkit-clear-button {
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    margin: -10px; 
  }

  #timepicker::-webkit-input-placeholder {
      color: #333;
      font-weight: bolder;
      font-size: 14px;
      text-transform: none;
      padding: 10px 12px;
      padding-left: 5px;
      margin: 0;
  }

  #timepicker:-ms-input-placeholder {
      color: #333;
      font-weight: bolder;
      font-size: 14px;
      text-transform: none;
      padding: 10px 12px;
      padding-left: 5px;
      margin: 0;
  }

  #timepicker::placeholder {
      color: #333;
      font-weight: bolder;
      font-size: 14px;
      text-transform: none;
      padding: 10px 12px;
      padding-left: 5px;
      margin: 0;
  }

.prayertime-table {
    overflow-x: scroll;
     width: 100%;
}

/* .juma-scroll {
    overflow-x: scroll;
    width: 100%;
} */

::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0;
    /* background: rgb(238, 238, 238); */
  }

  .or-div {
      margin-top: 10px;
  }

  @media only screen and (max-width: 768px) {
      .small-font-size {
          font-size: 10px;
      }
      .small-font-size-dhuhr {
        font-size: 9px;
    }
    .small-font-size-maghrib {
        font-size: 8px;
    }
  }

  .small-font-size-dhuhr {
    font-size: "11.5px";
  }

  .small-font-size-maghrib {
    font-size: "10.3px";
  }

.select-label {
  max-width: 100%;
}

.select-iqama {
  width: 100%;
}

.time-col {
  padding: 12px;
    background: #ffffff!important;
    border: 1px solid #ffbc00;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgb(48 48 48 / 20%);
}

.main-card{
  box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
}

select, input, .ant-picker, textarea {
  border: 1px solid #70707091 !important;
  outline: none;
  color: #707070!important;
}

.input-pass input{
  border: none !important;
}

.ant-picker input {
  border: none!important;
  color: #707070!important;
}


::-webkit-input-placeholder {
  color: #707070!important;
  opacity: 1;
}


:-ms-input-placeholder {
  color: #707070!important;
  opacity: 1;
}


::placeholder {
  color: #707070!important;
  opacity: 1;
} 

.time-col label {
  color: #000!important;
}

.prayertimes-table tr th:nth-child(5),
.prayertimes-table tr td:nth-child(5) {
    min-width: 180px !important;
    width: 180px;
}

.swal-title {
  /* margin: 0px;
  font-size: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px; */
  font-size: 10px;
}

a.close-repeated-set {
  color: #d21a1a;
  font-size: 16px;
}

.repeated-set {
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 20px!important;
}
@font-face {
    font-family: 'WebFonts';
    src: url(/static/media/AlegreyaSans-Medium.7b2f33e7.woff);
  }

.mosque-settings .custom-label {
    font-size: "14px";
    font-family: "Arial";
    font-weight: "lighter";
}

.mosque-settings .cutom-input {
    height: 30px;
    border-radius: 2px;
    font-family: Arial;
    font-weight: 100;
}

.mosque-settings .custom-select {
    height: 30px;
    border-radius: 2px;
    font-family: Arial;
    font-weight: 100;
    padding-top: 5px;
    padding-bottom: 5px;
}
.mosque-set {
    /* margin-left: 200px; */
    width: auto;
    background: transparent;
    margin-top: 1px;
}

.btn-primary{
    background-color: #0bb0b6;
    border-color: #0bb0b6;
}

.btn-primary:hover {
    color: #0bb0b6!important;
    background-color: #fff;
    border-color: #0bb0b6;
}

.btn-secondary {
    background-color: #fff;
    border-color: #0bb0b6;
    color: #0bb0b6;
}

.btn-secondary:hover {
    color: #fff!important;
    background-color: #0bb0b6;
    border-color: #fff;
}

@font-face {
  font-family: 'WebFonts';
  src: url(/static/media/AlegreyaSans-Medium.7b2f33e7.woff);
}



.bodyy{
  background: linear-gradient(180deg, rgb(255 255 255 / 80%) 10%, rgb(255 255 255 / 80%) 80% ),url(/static/media/masjidImage1.deb79120.jpg) no-repeat center center;
  background-size: cover;
  height:100%;
  padding-top: 10px;
  padding-bottom: 30px;
  background-attachment: fixed;
}
.prayer-margin {
  margin-left: 200px;
  width: 76%;
}

.prayer-cont {
  /* width: auto; */
  background: white;
  /* width: calc(100% - 18px); */
  /* margin-left: 200px;
  width: 71.5%; */
}
@media only screen and (min-width:321px) and (max-width:768px)  {
  .prayer-margin {
    width: 76%;
    margin-left: 80px;
  }
}
@media only screen and (max-width: 1440px) {
  .prayer-margin {
    width: 72%;
    margin-left: 70px;
  }
}
@media only screen and (min-width: 2560px) {
 .prayer-margin {
   width: 57.5%;
   margin-left: 580px;
 }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.21875) and (-webkit-max-device-pixel-ratio: 1.2395833333333333), only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (-webkit-min-device-pixel-ratio: 1.3645833333333333) and (-webkit-max-device-pixel-ratio: 1.3854166666666667), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (-webkit-min-device-pixel-ratio: 1.5104166666666667) and (-webkit-max-device-pixel-ratio: 1.6041666666666667), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (-webkit-min-device-pixel-ratio: 1.6875) and (-webkit-max-device-pixel-ratio: 1.7083333333333333), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (-webkit-min-device-pixel-ratio: 1.7604166666666667), only screen and (min-resolution: 169dpi) {
  /* Your touch-specific css goes here */
 .prayer-margin {
   width: 57.5%;
   margin-left: 580px;
 }
}
.legend {
  font-size: 18px;
  margin: 10px auto;
  font-weight: 500!important;
  color: #0bb0b6;
}

.fieldset{
  border: 1px solid #dde2e7 !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  box-shadow:  0px 0px 0px 0px #000;
}

.newadd{
  cursor: text;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #374767;
  font-weight: lighter;
}

.newadd1{
  height: 30px;
  border-radius: 2px;
}


.newmosque-cont {
  background: white;
  /* width: 72%; */
  /* margin-left: 200px; */
}

.advertiser-table {
  width: 100%;
  overflow-x: scroll;
}

@media only screen and (max-width: 486px) {
  .advertiser-table {
    width: 100%;
    overflow-x: scroll;
  }
  
  .advertiser-table table {
    min-width: 800px;
  }
}
.makerecv{
    background-color:#558B2F;
    padding:1px 5px;
    border-color:#3C6F18;
    border-radius:3px;
    color:#fff;
    font-size:12px;
    margin-right: 5px;
    /* line-height: 1.5; */
    font-weight: 400;
    touch-action: manipulation;
}
.makerecv:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}




.modal {
    position: fixed;
    top: 64px;
    padding: 24px;
    left: 64px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 3px;
  }
@font-face {
  font-family: 'WebFonts';
  src: url(/static/media/AlegreyaSans-Medium.7b2f33e7.woff);
}


  .modal {
    width: 40%;
    height: 65%;
    margin-left: 25%;
    border-radius: 0px;
    box-sizing: border-box;
    display: block;
  }

  .modheader {
    color: #374767;
  }

  .mos {
    font-weight: 400 !important;
    font-size: 14px;
    /* display: inline-block; */
    max-width: 100%;
    margin-bottom: 5px;
    color:#5a5858;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  }

  .mos1 {
  cursor: text;
  font-family: 'WebFonts';
  /* font-size: 10px; */
  color: #5a5858;
  width: 100%;
  border-radius: 2px;
  padding: 4px 3px 4px;
  border: 1px solid #e4e7eb;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .mos2 {
    color: #333333;
    font-weight: bold;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  }

  .mos3 {
    width: 100%;
    height: 100%;
    border: 1px solid #e4e7eb;
  }

  .que::-webkit-calendar-picker-indicator {
    opacity: 10;
   background-color: #e4e7eb;
    padding: 15px 9px 15px 9px;
 }

 .que1::-webkit-calendar-picker-indicator {
  opacity: 10;
 background-color: #e4e7eb;
  padding: 15px 9px 15px 9px;
}

.tog {
  float: right;
  padding: 6px 12px;
  color: white;
  background-color: #558B2F!important;
  font-size: 14px;
  border-radius: 2px;
  border-color: #3C6F18;
  text-align: center;
}
  
.tog1 {
  background-color: #f7f9fa;
    /* border-top: 1px solid #e1e6ef; */
    padding: 10px 15px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    /* margin-top: -4%; */
}

/* .scrollable {
  overflow-y: auto;  
} */
.brochure-wrapper {
    border: 1px solid black;
    margin: 1rem;
    width:85%;
    margin-left: 90px;
}

.brochure-wrapper svg {
    width: 2rem;
    height: 2rem;
}

.brochure-wrapper .ant-card-meta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.brochure-wrapper .hor-ad img {
    max-height: 12rem;
}

.brochure-wrapper .ant-card {
    margin: 0.3rem;
    min-height: 100%;
}


/* NEW CSS STARTS HERE */

.top {
    display: flex;
    margin: 1rem 0rem;
}

.top .ad {
    width: 25%;
    height: 206px;
}
.top .da {
    width: 20%;
    height: 206px;
}
.top .da img {
    height: 100%;
    width: 100%;
    margin-top: 0.3rem;
    padding: 0.3rem;
}

.top .ad img {
    height: 100%;
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.3rem;
}



.bottom {
    display: flex;
   
}

.bottom .calendar {
    width: 60%;
}

.bottom .sidebar {
    width: 40%;
    
}

.add{
    width:20%;
}

.bottom .sidebar .ad img {
    width: 100%;
    height: 133px;
    padding: 0.3rem;
}

.bottom .calendar img {
    padding: 0.3rem;
    width: 100%;
    height: 100%;
}

.actions-wrapper {
    margin: 1rem;
    padding: 2rem;
    border: 1px solid grey;
    border-radius: 5px;
}


/* PRINT CSS */

@media print {
    body {
        width: 21cm;
        height: 29.7cm;
        margin: 30mm 45mm 30mm 45mm;
        /* change the margins as you want them to be. */
    }
}

.card-header {
    border: 0px;
    background: #0bb0b6;
    color: #fff;
    font-size: 18px;
}

.card-header h5 {
    color: #fff;
    margin: 0px;
}

.card-header button {
    border: 1px solid #fff;
    background: #0bb0b6;
    font-size: 14px;
    padding: 6px 10px;
}

.card-body {
    padding: 13px;
}
