body {
  background-color: white;
  overflow-x: hidden;
}

div#root {
  max-width: 1920px;
  margin: 0 auto;
}


.loginh1{
  font-size: 24px;
  padding-top: 20px;
  font-family: Helvetica;
  font-weight: 100;
  padding-left: 55px;
  /* padding-bottom: 30px; */
  
}

.loginh2{
  
  padding-top: 6px;
  
  font-size:32px;
  font-weight:600;
}


.username {
  width: 90%;
  padding: 12px ;
  margin: 0px 0px 8px;
  cursor: text;
  box-sizing: border-box;
  
  border-radius: 2px;
  
  height: 40px;
  color: rgb(11, 11, 11);
  font-family: Helvetica;
  margin-left: 10px;
  border: #e1e1e1 solid 2px;
  margin-top: 50px;
}

.passwordlog{
  width: 90%;
  padding: 12px ;
  margin: 0px 0px 8px;
  box-sizing: border-box;
  
  border-radius: 2px;
  
  color: rgb(10, 10, 10);
  height: 40px;
  font-family: Helvetica;
  margin-left: 10px;
  border: #e1e1e1 solid 2px;
  margin-top: 12px;
}

.submitbuto{
  background-color: #D21A1A;
  color: #fff;
  padding: 10px;
  
  text-decoration: none;
  display: inline-block;
  
  cursor: pointer;
  border-radius: 2px;
  font-family:  Helvetica;
  margin-left: 0px;
  width: 90%;
  font-weight: bolder;
  border-color:#D21A1A;
  border: none;

  margin-left: 10px;
  margin-top: 10px;
}


.submitbuto1{
  background-color:#D21A1A;
  color: white;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 0px 0px;
  cursor: pointer;
  
  font-family:  Helvetica;
  margin-left: 22px;
  width: 75%;
  font-weight: bolder;
  border-color: rgba(27,31,35,.15);
  
  margin-left: 20px;
}

.submitbuto2{
  background-color:#D21A1A;
  color: white;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 0px 0px;
  cursor: pointer;
  
  font-family: Helvetica;
  
  width: 50%;
  font-weight: bolder;
  border-color: rgba(27,31,35,.15);
  box-shadow: 0 1px 0 rgba(27,31,35,.04), inset 0 1px 0 hsla(0,0%,100%,.25);
  transition: background-color .2s cubic-bezier(.3,0,.5,1);
  
  margin-left: 80px;
}


.forgot {
  /* width: 100%; */
  /* padding: 12px 20px; */
  /* margin: 8px 0; */
  cursor: pointer;
  /* height: 40px; */
  font-family:  Helvetica;
  
}




.space {
  font-weight: 400;
  /* margin-left: 10px; */
  font-size: 17px;
}

/* .space1 {
  margin-left: 25px;
} */
  .submitbuto:hover {
    background-color: #dd4b4b;
  }



.lef1 {
 
  width: 100%;
  margin-left: 145px;
 
} 


.lef2 {
 
  width: 100%;
  line-height: 25px;
  font-family: Helvetica;
  font-weight: 500;
}

.para1 {
  /* margin-bottom: 26px; */
  font-size: 17px;
  color: #111111;
}

 


 .submit {
  background-color: #2EA44F;
  
  color: white;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 20px 0px 0px;
  cursor: pointer;
  border-radius: 5px;
  font-family:  Arial,sans-serif;
  margin-left: 22px;
  width: 200px;
  font-weight: bolder;
  
  border-color: rgba(27,31,35,.15);
  box-shadow: 0 1px 0 rgba(27,31,35,.04), inset 0 1px 0 hsla(0,0%,100%,.25);
  transition: background-color .2s cubic-bezier(.3,0,.5,1);
 }

 .submit:hover {
  background-color: #1b612f;
 }


 .text {
  width: 20%;
  padding: 12px 20px;
  margin: 8px 0;
  cursor: text;
  box-sizing: border-box;
  border: solid rgba(238, 232, 232, 0.678);
  border-radius: 5px;
  background-color: rgb(255, 249, 249);
  height: 40px;
  color: rgb(10, 10, 10);
  font-family:  Arial,sans-serif;
 }
 .lgin{
   background-color: #EEF2F6;
   padding: 10px 0px 10px;
   /* padding-left: 187px; */
   /* margin-left: 185px; */
   font-size: 25px;
   margin: 0 0 15px;
   position: relative;
   font-weight:bold;
   /* z-index: 2; */
 }
 .lgin1r{
  background-color: #EEF2F6;
  padding: 10px 0px 10px;
  /* padding-left: 187px; */
  /* margin-left: 185px; */
  font-size: 25px;
  margin: 0 0 15px;
  position: relative;
  font-weight:bold;
  /* z-index: 2; */
}

 .log {
  border: 1px solid #e5e5e5;
  border-bottom-color: transparent;
  margin-top: 30px;
  /* margin-left: 40px; */
  padding-bottom: 20px;
  /* width:70%; */
  background-color: rgba(255,255,255,.97);
  box-shadow: 0 3px rgba(48,48,48,0.1);
 }

 .submitbuto2:hover {
  background-color: #dd4b4b;
}

.submitbuto:hover {
  background-color: #dd4b4b;
}




.new-checkbox  {
  border: 2px solid #e3e3e3;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    cursor: pointer;
    float: left;
    height: 16px;
    
    position: relative;
    width: 16px;
    margin-left: 10px;
   
    height: 25px;
}




/* body {
  font: 0.8em arial, helvetica, sans-serif;
} */

#header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#header li {
  float: left;
  border: 1px solid;
  border-bottom-width: 0;
  margin: 0 0.5em 0 0;
}

#header a {
  display: block;
  padding: 0 1em;
}

#header #selected {
  position: relative;
  top: 1px;
  background: white;
  height: 60px;
  width: 120px;
  border-radius: 3px;
  padding-left: 28px;
  padding-top: 5px;
  font-size: 26px;
  border: 1px solid #e5e5e5;
  border-bottom: none;
}
#header #selected1r {
  position: relative;
  top: 1px;
  background: white;
  height: 60px;
  width: 240px;
  border-radius: 3px;
  padding-left: 28px;
  padding-top: 5px;
  font-size: 26px;
  border: 1px solid #e5e5e5;
  border-bottom: none;
}

#content-l {
  border: 1px solid #e5e5e5;
  clear: both;
  width: 85%;
  height: 300px;
  border-radius: 3px;
  box-shadow: 0 3px rgba(48,48,48,.1);
  -webkit-box-shadow: 0 3px rgba(48,48,48,.1);
}
#content1r {
  border: 1px solid #e5e5e5;
  clear: both;
  width: 480px;
  height: 260px;
  border-radius: 3px;
  box-shadow: 0 3px rgba(48,48,48,.1);
  -webkit-box-shadow: 0 3px rgba(48,48,48,.1);
}

h1 {
  margin: 0;
  /* padding: 0 0 1em 0; */
}

.whole {
  margin-left: 40px;
  margin-top: 10px;
}

/* @media only screen {
  body {
    overflow-x: hidden !important;
  }
} */
@media only screen and (min-width: 280px) and (max-width: 320px) {
  #content-l {
    width: 100%;
    height: 320px;
  }
  #content1r {
    width: 297px;
    height: 320px;
  }
  /* .lgin {
    padding-left: 40%;
  }  */
  /* .lgin1r {
    padding-left: 20%;
  }  */
  .lef1 {
    margin-left: -16%;
  }
  .lef2 {
    margin-top: 20%;
  }
}
@media only screen and (min-width:321px) and (max-width:767px){
  #content-l {
    width: 100%;
    height: 300px;
  }
  #content1r {
    width: 400px;
    height: 300px;
  }
  .lgin {
    /* padding-left: 35px; */
    font-size: 35px;
  } 
  /* .lgin1r {
    padding-left: 20%;
  }  */
  .lef1 {
    margin-left: -10%;
  }
  .lef2 {
    margin-top: 8%;
  }
}
@media only screen and (width: 768px) {
  #content-l {
    width: 100%;
    height: 300px;
  }
  #content1r {
    width: 350px;
    height: 300px;
  }
  .lef1 {
    margin-left: -5%;
  }
  .lef2 {
    margin-top: 8%;
  }
}
@media only screen and (min-width:769px) and (max-width:1260px){
  #content-l {
    width: 100%;
    height: 260;
  }
  #content1r {
    width: 400px;
    height: 260;
  }
  .lgin {
    /* padding-left: 55px; */
    font-size: 35px;
  }
  /* .lgin1r {
    padding-left: 5%;
  }   */
  .lef1 {
    margin-left: 0px;
  }
  .lef2 {
    float: right;
  }
}
@media only screen and (min-width: 2560px){
  #content-l {
    width: 100%;
    height: 280px;
  }
  #content1r {
    width: 45%;
    height: 280px;
  }
  .lgin {
    /* margin-left: 8.2%; */
    text-align: left;
  } 
  /* .lgin1r {
    padding-left: 25.5%;
  }  */
  .lef1 {
    margin-left: 605px;
  }
  .lef2 {
    float: right;
  }
}

ul li {
  list-style-position: outside;
}

.logout-button{
  color: #0bb0b6;
  background: #fff;
  border: 1px solid #0bb0b6;
  margin-right: 15px;
}

.logout-button:hover{
  background: #0bb0b6;
  color: #fff;
}

