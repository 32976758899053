@font-face {
  font-family: 'WebFonts';
  src: url(../assets/fonts/AlegreyaSans-Medium.woff);
}

@font-face {
  font-family: 'WebFonts2';
  src: url(../assets/fonts/AlegreyaSans-Medium.ttf);
}

.nav-menuu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 30px;
  justify-content: center;
  background-color: transparent;
}
.addiv1 {
    height: 60px;
    /* border: solid 1px #D21A1A; */
    padding-top: 9px;
    }

.anc1 {
    text-decoration: none;
    color: inherit;
}
.anc2 {
      padding: 8px 20px;
      border-radius: 4px;
      outline: none;
      border: none;
      font-size: 18px;
      color: white;
      cursor: pointer;
      background-color: #D21A1A;
      text-decoration: none;
    }

    .hov:hover{
        color: #558B2F;
        
    }
    
    .content:hover {background-color: #f8fafc !important; zoom: 1.1;}

    .hv1:hover{
      border-bottom:solid 1px green;
    }

    .sub-header-menu p {
      margin-bottom: 0px;
  }
  
  .sub-header-menu .dropdown{
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
  }
  
  .sub-header-menu .dropdown:hover{   
      background: #fff9;
  }

  .sub-header-menu{
    background: #eef2f6;
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
    z-index: 1;
    position: relative;
}


    /* .masjidheader {
      margin-left: 105px;
      font-family: 'Alegreya Sans Medium';
      margin-bottom: 0px;
    }
    .masjidheader1 {
      background-color: white;
      padding-left: 6%;
    } */