:root {
  --primary: #1888ff;
}

.btnnav{
  background-color:#D21A1A;
  border-radius: 2.5px; 
}

.btnnav:hover {
  background-color: #dd4b4b;
}


.headerlog:hover{
  background-color: #dd4b4b;
}

.headerlog{
  background-color: #D21A1A;
}