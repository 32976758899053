div#root {
  max-width: 1920px;
  margin: 0 auto;
}

@font-face {
  font-family: 'PCFonts';
  src: url(./assets/fonts/AlegreyaSans-Medium.woff) , 
       url(./assets/fonts/AlegreyaSans-Medium.ttf)  ,
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  border: 3px solid #fff;
  padding: 20px;
  height: 90vh;
  justify-content: center;
  font-size: 3rem; 
}

.playstore-text h2{
        color: #000;
        font-size: 26px;
        margin-bottom: 0px;
        margin-left: 8px;
    }
.playstore-text p{
        color: #000;
        font-size: 14px;
        line-height: 1.5;
        margin-left: 8px;
    }

    .marketing-banner{
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      margin-bottom: 30px;
      padding-bottom: 20px;
    }

    .offer-box {
      border: 1px solid #c9c9c9;
      background: linear-gradient(
  268deg
  , #eeeeee, transparent);
      margin-bottom: 40px;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }

          .offer-box h5{
            font-weight: 800;
            font-size: 30px;
            color: #fff;
            background: #0bb0b6;
            padding: 10px 20px;
          }
