table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  /* text-align: left; */
  padding: 8px;
}

tr:nth-child(even) {
  /* background-color: #EEF2F6; */
  border-collapse: collapse;
}

#align{
  margin: auto;
  margin-top: 3rem;
  width: 80%;
  border: solid 1px white;
  padding:60px;
  background-color: white;
  border-radius: 1mm  ;

}
