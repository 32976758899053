@font-face {
  font-family: 'WebFonts';
  src: url(../../assets/fonts/AlegreyaSans-Medium.woff);
}



.bodyy{
  background: linear-gradient(180deg, rgb(255 255 255 / 80%) 10%, rgb(255 255 255 / 80%) 80% ),url(../../images/masjidImage1.jpg) no-repeat center center;
  background-size: cover;
  height:100%;
  padding-top: 10px;
  padding-bottom: 30px;
  background-attachment: fixed;
}
.prayer-margin {
  margin-left: 200px;
  width: 76%;
}

.prayer-cont {
  /* width: auto; */
  background: white;
  /* width: calc(100% - 18px); */
  /* margin-left: 200px;
  width: 71.5%; */
}
@media only screen and (min-width:321px) and (max-width:768px)  {
  .prayer-margin {
    width: 76%;
    margin-left: 80px;
  }
}
@media only screen and (max-width: 1440px) {
  .prayer-margin {
    width: 72%;
    margin-left: 70px;
  }
}
@media only screen and (min-width: 2560px) {
 .prayer-margin {
   width: 57.5%;
   margin-left: 580px;
 }
}
@media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
  /* Your touch-specific css goes here */
 .prayer-margin {
   width: 57.5%;
   margin-left: 580px;
 }
}
.legend {
  font-size: 18px;
  margin: 10px auto;
  font-weight: 500!important;
  color: #0bb0b6;
}

.fieldset{
  border: 1px solid #dde2e7 !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow:  0px 0px 0px 0px #000;
          box-shadow:  0px 0px 0px 0px #000;
}

.newadd{
  cursor: text;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #374767;
  font-weight: lighter;
}

.newadd1{
  height: 30px;
  border-radius: 2px;
}


.newmosque-cont {
  background: white;
  /* width: 72%; */
  /* margin-left: 200px; */
}

.advertiser-table {
  width: 100%;
  overflow-x: scroll;
}

@media only screen and (max-width: 486px) {
  .advertiser-table {
    width: 100%;
    overflow-x: scroll;
  }
  
  .advertiser-table table {
    min-width: 800px;
  }
}