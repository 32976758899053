@import '~antd/dist/antd.css';
.brochure-wrapper {
    border: 1px solid black;
    margin: 1rem;
    width:85%;
    margin-left: 90px;
}

.brochure-wrapper svg {
    width: 2rem;
    height: 2rem;
}

.brochure-wrapper .ant-card-meta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.brochure-wrapper .hor-ad img {
    max-height: 12rem;
}

.brochure-wrapper .ant-card {
    margin: 0.3rem;
    min-height: 100%;
}


/* NEW CSS STARTS HERE */

.top {
    display: flex;
    margin: 1rem 0rem;
}

.top .ad {
    width: 25%;
    height: 206px;
}
.top .da {
    width: 20%;
    height: 206px;
}
.top .da img {
    height: 100%;
    width: 100%;
    margin-top: 0.3rem;
    padding: 0.3rem;
}

.top .ad img {
    height: 100%;
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.3rem;
}



.bottom {
    display: flex;
   
}

.bottom .calendar {
    width: 60%;
}

.bottom .sidebar {
    width: 40%;
    
}

.add{
    width:20%;
}

.bottom .sidebar .ad img {
    width: 100%;
    height: 133px;
    padding: 0.3rem;
}

.bottom .calendar img {
    padding: 0.3rem;
    width: 100%;
    height: 100%;
}

.actions-wrapper {
    margin: 1rem;
    padding: 2rem;
    border: 1px solid grey;
    border-radius: 5px;
}


/* PRINT CSS */

@media print {
    body {
        width: 21cm;
        height: 29.7cm;
        margin: 30mm 45mm 30mm 45mm;
        /* change the margins as you want them to be. */
    }
}

.card-header {
    border: 0px;
    background: #0bb0b6;
    color: #fff;
    font-size: 18px;
}

.card-header h5 {
    color: #fff;
    margin: 0px;
}

.card-header button {
    border: 1px solid #fff;
    background: #0bb0b6;
    font-size: 14px;
    padding: 6px 10px;
}

.card-body {
    padding: 13px;
}