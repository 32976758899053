@font-face {
    font-family: 'WebFonts';
    src: url(../../assets/fonts/AlegreyaSans-Medium.woff);
  }

.mosque-settings .custom-label {
    font-size: "14px";
    font-family: "Arial";
    font-weight: "lighter";
}

.mosque-settings .cutom-input {
    height: 30px;
    border-radius: 2px;
    font-family: Arial;
    font-weight: 100;
}

.mosque-settings .custom-select {
    height: 30px;
    border-radius: 2px;
    font-family: Arial;
    font-weight: 100;
    padding-top: 5px;
    padding-bottom: 5px;
}
.mosque-set {
    /* margin-left: 200px; */
    width: auto;
    background: transparent;
    margin-top: 1px;
}

.btn-primary{
    background-color: #0bb0b6;
    border-color: #0bb0b6;
}

.btn-primary:hover {
    color: #0bb0b6!important;
    background-color: #fff;
    border-color: #0bb0b6;
}

.btn-secondary {
    background-color: #fff;
    border-color: #0bb0b6;
    color: #0bb0b6;
}

.btn-secondary:hover {
    color: #fff!important;
    background-color: #0bb0b6;
    border-color: #fff;
}
