/* body{
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
} */
select{
    font-weight: bolder;
    
    color:#333;
   
    width:75%;
    min-width:100%;
    padding:10px 12px;
    
}
option{
    color:#333;
}

.bdyy{
    background-image: url('../images/mountain1.jpeg');
    height:100%;
    padding-top: 10px;
    padding-bottom: 30px;
}
label{
    
    font-size: 13px;
    max-width: 100%;
    font-weight: 700;
    margin-bottom: 5px
}


input[type=time]::-webkit-datetime-edit-ampm-field{
    display: none;
  }

  input[type=time]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance:none;
    appearance: none;
    margin: -10px; 
  }

  #timepicker::placeholder {
      color: #333;
      font-weight: bolder;
      font-size: 14px;
      text-transform: none;
      padding: 10px 12px;
      padding-left: 5px;
      margin: 0;
  }

.prayertime-table {
    overflow-x: scroll;
     width: 100%;
}

/* .juma-scroll {
    overflow-x: scroll;
    width: 100%;
} */

::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0;
    /* background: rgb(238, 238, 238); */
  }

  .or-div {
      margin-top: 10px;
  }

  @media only screen and (max-width: 768px) {
      .small-font-size {
          font-size: 10px;
      }
      .small-font-size-dhuhr {
        font-size: 9px;
    }
    .small-font-size-maghrib {
        font-size: 8px;
    }
  }

  .small-font-size-dhuhr {
    font-size: "11.5px";
  }

  .small-font-size-maghrib {
    font-size: "10.3px";
  }

.select-label {
  max-width: 100%;
}

.select-iqama {
  width: 100%;
}

.time-col {
  padding: 12px;
    background: #ffffff!important;
    border: 1px solid #ffbc00;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgb(48 48 48 / 20%);
}

.main-card{
  box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
}

select, input, .ant-picker, textarea {
  border: 1px solid #70707091 !important;
  outline: none;
  color: #707070!important;
}

.input-pass input{
  border: none !important;
}

.ant-picker input {
  border: none!important;
  color: #707070!important;
}


::placeholder {
  color: #707070!important;
  opacity: 1;
} 

.time-col label {
  color: #000!important;
}

.prayertimes-table tr th:nth-child(5),
.prayertimes-table tr td:nth-child(5) {
    min-width: 180px !important;
    width: 180px;
}

.swal-title {
  /* margin: 0px;
  font-size: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px; */
  font-size: 10px;
}

a.close-repeated-set {
  color: #d21a1a;
  font-size: 16px;
}

.repeated-set {
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 20px!important;
}