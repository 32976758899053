
.navbarr {
  background: white;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border: 1px solid rgb(209, 204, 204);
  width: auto;
}

.navbarr-logo {
  color: black;
  justify-self: start;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-weight: bolder; 
  max-width: auto;
  height: auto;
}


.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  
  width: 65vw;
  justify-content: end;
  margin-right: 2rem;
  margin-top: 42px;
}
.map-search {
  height: 80px;
  padding-top: 25px;
  z-index: 1;
  width: 600px;
}
.search-maploc {
  border-color: #e1e1e1;
  border-style: solid;
  margin-left: 0px;
  width: 540px;
  height: 40px;
  font-family: Arial;
  box-sizing: border-box;
}
.masajid-logo {
  width: 300px;
  /* margin-right: 20px; */
}
.butt-go {
  /* margin-top: 215%; */
  background: none;
  color: #0bb0b6;
  /* float: right; */
  /* margin-right: 730%; */
  font-size: 16px;
  border: none;
  font-family: Arial;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 20px;
}

.search-logo1 {
  height: 41px;
  width: 47px;
}
/* @media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
  
  
  .map-search {
    margin-top: 100px;
    padding-left: 250px;
  }
  .navbarr {
    height: auto;
    width: auto;
  }
  .navi-btn {
    padding-top: 48px;
    margin-right: 50px;
  }
} */
.navi-btn {
  margin-top: 23px;
  margin-left: 0px;
  display: flex;
}

.pc{
  height: 30px;
  width: 100px;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  
}

.nav-links {
  /* color: black; */
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #003d7a;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  color: white;
}

.fa-bars {
  color: #fff;
}

.nav-links1-mobile {
  display: none;

}

.menu-icon {
  display: none;
}


.nav-button {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-button1 {
  display: flex;
  align-items: center;
  height: 80px;
  padding-top: 25px;
}

.dropbtn1 {
  font-size: 10px;
}

.wrap1 {
  display: flex;
  align-items: center;
  height: 80px;
}
.search-maploc {
  width: 430px;
}

.dropbtn {
  color: black;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  
}




/* The container <div> - needed to position the dropdown content */
.dropdown {
  display: inline-block;
}




/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  background-color: transparent;
  width: 200px;
}



/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;border-radius: 1px;
}




/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;
text-decoration: none;}



/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}



/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color:#D21A1A;
color:white;}



.dropbtn1 {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  background-color: transparent;
}


.butoanc1 {
  text-decoration: none;
  color: white;
  font-size: 16px;
  border: none;
  
}

.dropbtn2 {
    background-color:#D21A1A;
    height: 30px;
    width: 60px;
    border-radius: 2px;
    color: white;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.dropbtn1:hover {
  background-color:#D21A1A;
  color:white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
} 


.dropbtn11 {
  padding: 10px;
  margin-bottom: 25px;
  
  border: none;
  background-color: transparent;
}

.dropbtn11:hover {
  background-color:#D21A1A;
  color:white;
  text-decoration: none;
  cursor: pointer;
  
  height: 44px;
} 

.search-logo {
 text-align: center;
 padding-top: 10px;
}
.search-logo-row {
  padding-left: 80px;
}
.search-logo-img {
  height: 41px;
  width: 47px;
  margin-right: 10px;
}

::-webkit-input-placeholder {
  color: #9e9d9d;
  font-weight: 450;
  font-size: 16px;
  padding-left: 5px;
  font-family: Arial
  
}

::-webkit-input {
  border-radius: none;
}
.searchinput {
  border-color: #e1e1e1;
  border-top-color: #e1e1e1;
  
}




.newheader {
    background: rgba(255,255,255,.95);
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
    z-index: 10;
    position: relative;
    height: 90px;
    padding: 0;
    width: 100%;
}


.logoimage {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
}

.searchinput {
    box-sizing: border-box;
    height: 40px;
    /* padding-right: 45px; */
    /* margin: 0; */
    width: 91%;
}

/* .focus:focus {
  outline: 1px solid #e1e1e1;
} */
.focus {
  border-color: #e1e1e1;
  border-style: solid;
  width: 100%;
  height: 40px;
  font-family: Arial;
  box-sizing: border-box;
  /* float: left; */
  /* margin-left: 17%;
  vertical-align: top; */
}

@media only screen and (min-width: 280px) and (max-width: 320px) {
  .logoimage {
   /* float: left; */
   /* margin-left: 3%; */
   padding-bottom: 5%;
  }
  .focus {
    width: 100%;
    height: 40px;
    /* margin-bottom: 2%; */
    /* margin-left: 2.5%; */
  }
  .search-logo {
    padding-top: 0px;
  }
  .search-logo-row {
    padding-left: 0px;
  }
  .newheader {
    height: auto;
  }
  .search-logo-img {
    display: none;
  }
  /* .butt-go {
    margin-top: -13%;
    float: left;
    margin-left: 265px;
  } */
  
}

@media only screen and (min-width:321px) and (max-width:767px) {
  .logoimage {
    /* float: left;
    margin-left: 3%; */
    padding-bottom: 0%;
   }
   .newheader {
    height: 60%;
  }
  .search-logo-img {
    display: none;
  }
  .focus {
    width: 100%;
    height: 40px;
    /* margin-bottom: 2%;
    margin-left: -23%; */
  }
  /* .butt-go {
    margin-top: -180%;
    float: left;
    margin-left: 1555%;
  } */
  
}
@media only screen and (width: 768px) {
  .search-logo-row {
    padding-left: 10px;
  }
  /* .focus {
    margin-left: 10%;
  } */
  
}
@media only screen and (min-width:1440px) {
  .logoimage {
    
    /* margin-left: 50%; */
    padding-bottom: 0%;
   }
   /* .butt-go {
     margin-top: 45px;
    margin-left: -3px;
  } */
  
}

/* @media only screen and (min-width: 768px) {
  .newheader {
    height: auto;
  }
  .logoimage {
    float: left;
    margin-left: 15%;
    margin-bottom: 5%;
   }

   .focus {
    width: 140%;
    height: 40px;
    margin-left: 10%;
  }
  .search-logo-img {
    float: left;
  }
  
  .butt-go {
    margin-top: 315%;
    margin-right: 5%;
  }
} */
@media only screen and (min-width:769px) and (max-width:1260px) {
  /* .search-logo-img {
    float: left;
  } */
  /* .search-logo {
    margin-left: -9%;
    margin-top: 1%;
  } */
  .focus {
    width: 100%;
    height: 40px;
    /* margin-left: 5%; */
  }
  
   /* .butt-go {
    margin-top: 300%;
    margin-right: -20%;
  } */
}
@media only screen and (min-width: 2560px) {
  /* .search-logo-img {
    float: left;
  } */
  .focus {
    width: 100%;
    height: 40px;
    /* margin-left: -5%; */
  }
  
  /* .butt-go {
    float: right;
    margin-right: 2999%;
  } */
   .newheader {
    margin-left: 8.5%;
  }
}
/* @media only screen and (min-width:321px) and (max-width:768px) {
  .butt-go {
    margin-right: 100px;
  }
  .search-logo1 {
    float: right;
  }
}
@media only screen and (min-width:769px) and (max-width:1260px) {
  .butt-go {
    margin-left: 200px;
  }
  .search-logo1 {
    float: right;
  }
}
@media only screen and (max-width:1440px) {
  .butt-go {
    margin-left: 475px;
  }
}
@media only screen and (min-width: 2560px) {
  .butt-go {
    margin-left: 945px;
  }
} */

.header-search {
  width: 100%;
  height: 40px;
  position: relative;
}

.search-wrapper {
  width: 100%;
}

.fa-search {
  color: #bbb;
} 

.focus{
  border-radius: 3px;
}