.home {
    background-color: white;
}

.masjid1 {
    width: 360px;
    height: auto;
    display: flex;
    margin-left: 30px;
    padding-right: 35px;
    margin-top: 10px;
    /* padding:5px; */
}

#plcs {
    border: solid 1px white;
    width: 95%;
    margin-left: 25px;
    height: 20px;
    text-transform: capitalize;
    font-size: 20px;
    color: #D21A1A;
}

#plcs1 {
    border: solid 1px white;
    width: 95%;
    margin-left: 25px;
    height: 20px;
    font-size: 15px;
    color: #696c70;
}

#pls {
    border: solid 1px white;
    width: 100%;
    margin-left: 38px;
    color: #3f3f41;
    font-weight: bold;
    font-size: 15px;
    /* margin-top: 30px; */
}

.btnhome {
    /* padding: 2px 5px 2px 5px; */
    padding: 7px 10px;
    /* padding-left: none; */
    /* margin-left: 126px; */
    border-radius: 2px;
    outline: none;
    border: none;
    font-size: 14px;
    color: #323232;
    cursor: pointer;
    background-color: #ffbc00;
    border: 1px solid #fff;
    /* width: 120px;
    height: 30px; */
    margin-bottom: 10px;
}

.custom-toggle {
    position: relative;
    width: 1px!important;
    z-index: 10;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 40%);
}

.custom-toggle span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
}
.custom-toggle span i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #D21A1A;
}

.hidden.custom-toggle span i {
    transform: rotate(
180deg
) translate(+50%, -20%,);
}

.btnhome:hover{
    border: 1px solid #ffbc00;
    color: #ffbc00;
    background: #fff;
}

.masjids {
    width: 360px;
    margin-top: 5px;
    display: flex;
    border-color: #e1e1e1;
    border-style: solid;
    border-width: 0;
    zoom: 1;
}

.masjid2 {
    width: 360px;
    height: auto;
    margin-top: 20px;
}

.div1 {
    width: 30%;
    height: auto;
}

/* .cardd {
    padding-top: 10px;
    margin-top: 30px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, .97);
    border: solid 1px #e5e5e5;
    border: solid 1px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 3px rgba(48, 48, 48, .1);
    -moz-box-shadow: 0 3px rgba(48, 48, 48, .1);
    box-shadow: 0 3px rgba(48, 48, 48, .1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
} */
.cardd-logo {
    border-radius: 50px;
    height: 70px;
    width: 70px;
    margin-right: 15px;
}

.cardd{
    padding-top: 15px;
    padding-bottom: 5px;
    margin-top: 30px;
    margin: 20px 0px 10px 10px!important;
    background-color: #fff;
    background-color: hsla(0,0%,100%,.97);
    border: 1px solid #e5e5e5;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 2px 6px rgb(48 48 48 / 10%);
    border-radius: 3px;
    border-top: 3px solid #FFBC00
}

/* .cardd-logo{    
    margin-right: 15px;
} */

.div2 {
    width: 108%;
    float: left;
    height: 550px;
}

.card-form {
    width: calc( 100% - 80px );
    color: #707070;
}



/* width */
::-webkit-scrollbar {
    width: 5px;
    
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white; 
    
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5; 
    border-radius: 5px;
    
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white; 
    height: 5px;
  }

  
  .headad1 {
      margin-left: 88%;
      padding-top: 16px;
  }
  @media only screen and (min-width: 280px) and (max-width: 320px) {
      .cardd-logo {
          height: 50px;
          width: 50px;
      }
  }
  /* .headad2 {
      height: 60px;
      border-right: none;
      border-top: none;
      padding-left: 65px;
      padding-right: 50px;
      background-color: #EEF2F6;
  } */
  /* @media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
   .headad1 {
       margin-left: 70%;
   }
   .headad2 {
       margin-left: -130px;
       margin-right: -115px;
   }
} */

.sidebar-card-title {
    color: #707070;
    font-weight: 500;
    margin-bottom: 3px;
    font-size: 18px;
}

.map-wrapper > div, .map-wrapper{
    width: 100%;
    height: 100%;
}



/*New Services css*/
.service-selector{
    border: 1px solid #FFDC7B;
    padding: 40px 20px;
  }  

  .service-title h3{
    font-size: 28px;
    margin: 10px auto;
    font-weight: 500 !important;
    color: #707070;
    text-align: center;
    margin-bottom: 30px;
  }

  .service-box {
    padding: 10px;
    margin: 10px;
    width: 100%;
    max-width: 220px;
  }

  .service-box a {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 24px rgb(0, 0, 0, 0.1);
    border-top: 3px solid #FFBC00;
    padding: 50px 10px 10px;
  }

  .service-box a:hover{
    box-shadow: 0px 2px 12px rgb(0, 0, 0, 0.1);
    border: 2px solid #FFBC00;
  }

  .service-box img {
    width: 100px;
    margin-bottom: 10px;
  }

  .service-box h4 {
    text-align: center;
    height: 60px;
    color: #0BB0B6;
    font-size: 19px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
/*New Services css END*/

/*Print on Your own css*/
.icon-text {
    /* padding-left: 3.9rem; */
    align-items: baseline;
    justify-content: center;
}

/* .icon-text2 {
    padding-left: 3.9rem;
} */

.detail {
    padding-left: 10px;
}

@media screen and (max-width: 766px) {
    .detail {
        text-align: center;
        /* padding-left: 0px !important; */
    }

    .icon-icon {
        text-align: center;
    }

    .upload-text {
        display: flex;
        justify-content: center;
    }
}

.page-text {
    color: #2C3E50;
    font-size: 21px;
    border-bottom: 2px solid rgb(227, 227, 227);
    padding-top: 40px;
    font-weight: bold;
}

.shipping-method {
    font: normal normal 600 14px/23px Mulish;
    letter-spacing: 0px;
    color: #07411A;
    opacity: 1;
}

.Method-box {
    border: 2px solid #CED4DA;
    background: #FFFFFFFA 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 40px;
}

.method-type {
    font: italic normal 600 15px/20px Mulish;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}

.method-price {
    font: normal normal 600 17px/28px Mulish;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.method-price2 {
    text-align: left;
    font: normal normal 600 17px/28px Mulish;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-left: 12rem;
}

.method-price3 {
    text-align: left;
    font: normal normal 600 17px/28px Mulish;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-left: 8.8rem;
}

.upload-text {
    text-align: left;
    font: normal normal normal 15px/19px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    padding-left: 1rem;
}

.upload-box {
    background: #FFFFFFFA 0% 0% no-repeat padding-box;
    border: 1px solid #FFBC00;
    opacity: 1;
    padding: 15px;
    margin: 10px;
    width: 100%;
    /* max-width: 500px; */
}

.formcol {
    display: flex;
    justify-content: center;
}

.dashed-line {
    border-bottom: 2px dashed rgb(227, 227, 227);
}

.title-price {
    text-align: center;
    font: normal normal medium 18px/22px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.price-box {
    border: 2px solid #CACACA;
    opacity: 1;
    /* margin: 10px; */
    margin-left: 30px;
    width: 100%;
    max-width: 140px;
}

.total-price {
    text-align: center;
    font: normal normal 600 25px/40px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    /* margin-left: 2rem; */
}

.pin-box {
    border: 2px solid #CACACA;
    opacity: 1;
    margin-bottom: 10px;
    margin-left: 10px;
    width: 100%;
    max-width: 100px;
}

.pin-no {
    text-align: center;
    font: normal normal 600 15px/30px Roboto;
    letter-spacing: 0px;
    color: gray;
    opacity: 1;
}

@media print {
    .pdf-container {
      display: none;
    }
  }
/*Print on Your own css END*/

/*Choose from our Design css*/
.template-select-box {
    padding: 10px;
    margin: 10px;
    width: 100%;
    max-width: 220px;
  }

  .template-select-box a {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E1E1E1;    
  }

  .template-select-box a:hover{
    box-shadow: 0px 2px 12px rgb(0, 0, 0, 0.1);
    border: 2px solid #FFBC00;
  }

  .template-select-box img {
    width: 100%;         
  }

  .check-new.custom-control{
    padding: 0px 4px;
  }

  .check-new .custom-control-label::before {
      border: 1px solid #707070;  
      left: 9px;
      top: 9px;  
      height: 20px;
      width: 20px;     
  }

  .check-new .custom-control-label::after {
    left: 15px;
    top: 15px;
    border-radius: 50%;
    width: 9px;
    height: 9px;
  }

  .check-new.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
      background: #fff;
      border-color: #FFBC00;
  }

  .check-new.custom-radio .custom-control-label{
      padding: 5px 14px 10px;            
      padding-left: 36px;
      border: 1px solid #E1E1E1;
      font-size: 16px;
      font-weight: 500;
  }

  .check-new.custom-radio .custom-control-input:checked ~ .custom-control-label{
    background: #FFBC00;
    border: 1px solid #FFBC00;
    color: #fff;
  }

  .check-new.custom-radio .custom-control-input:checked~.custom-control-label{
      border: 1px solid green;
  }

  .check-new.custom-radio .custom-control-input:checked~.custom-control-label::after {
      background: #0BB0B6;
      background-repeat: no-repeat;
      background-position: 4px 7px;
  }  

  /*Choose from our Design css END*/

  /*Work with designer service css*/
  .post-box {
    padding: 10px;
    margin: 10px;
    width: 100%;
    max-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 24px rgb(0, 0, 0, 0.1);
    border-top: 3px solid #FFBC00;
    padding: 50px 10px 10px;
  }

  .post-box img {
    width: 100px;
    margin-bottom: 10px;
  }

  .post-box h4 {
    text-align: center;
    height: 60px;
    color: #0BB0B6;
    font-size: 19px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  /* .post-box a {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 24px rgb(0, 0, 0, 0.1);
    border-top: 3px solid #FFBC00;
    padding: 50px 10px 10px;
  }

  .post-box a:hover{
    box-shadow: 0px 2px 12px rgb(0, 0, 0, 0.1);
    border: 2px solid #FFBC00;
  } */
  /*work with designer service css END*/