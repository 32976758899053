.dropbtn {
  color: #d21A1A;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  
}




/* The container <div> - needed to position the dropdown content */
.dropdown {
  display: inline-block;
  color: #D21A1A;
}




/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px #D21A1A; */
  z-index: 1;
}



/* Links inside the dropdown */
.dropdown-content a {
  /* color: #D21A1A; */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: white;
  
}




/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: white;
text-decoration: none;}



/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}



/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #D21A1A;
color:white;}



.dropbtn1 {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: none;
  background-color: transparent;
}


.butoanc1 {
  text-decoration: none;
  color: white;
  font-size: 16px;
  border: none;
  
}

.dropbtn2 {
    background-color: #D21A1A;
    height: 30px;
    width: 60px;
    border-radius: 2px;
    color: white;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.dropbtn1:hover {
  background-color: #D21A1A;
  color:white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
} 
.butoanc2:hover {
  text-decoration: none;
  background-color: #D21A1A;
  font-size: 16px;
  border: none;
}

.logout-button {
  color: #0bb0b6;
  background: #fff;
  border: 1px solid #0bb0b6;
  margin-right: 15px;
}

.logout-button:hover{
  background: #0bb0b6;
  color: #fff;
}

.myaccount-button{
  color: #fff;
  background: #0bb0b6;
  border: 1px solid #0bb0b6;       
}

.myaccount-button:hover{
  background: #fff;
  color: #0bb0b6;
}