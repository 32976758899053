@font-face {
    font-family: 'WebFonts';
    src: url(../../assets/fonts/AlegreyaSans-Medium.woff);
  }

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* body {
 font-family: Roboto,Arial,sans-serif;
 font-size: 14px;

} */
.wrapper {
    background-color: #fff;
    width: 70%;
    padding: 15px 16px 0px 16px;
    /* margin:  auto; */
    margin-left: 260px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    
}
.wrapper h2{
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 30px;
    text-align: center;
}
.input-name{
    width: 100%;
    position: relative;
    margin: 20px auto;
    
}
.lock{
    padding:8px 11px;
}

.name{
    width:100%;
    padding:8px 40px 8px 10px;
    border: 1px solid #cccccc;
    color: gray;
    -webkit-transition: all 0.30s ease-in-out;
      -moz-transition: all 0.30s ease-in-out;
      -ms-transition: all 0.30s ease-in-out;
      transition: all 0.30s ease-in-out;
    
}
.input-name span{
    margin-left: 35px;
}
.text-name{
    width: 100%;
    padding:8px 0 8px 40px;
}
.input-name i{
    position: absolute;
    font-size: 18px;
    border-right: 1px solid #cccccc;
}
.config{
    width: 100;
    position: relative;
    margin: 20px auto;
    border-top:1px solid turquoise;
    border-bottom:1px solid turquoise;
    padding-top: 10px;
    padding-bottom: 10px;
}
.subhead{
    font-size: 25px;
}
.com1{
        width: 100%;
        padding:8px 0 8px 40px;
}


.email{
    padding: 8px;
}
.text-name1{
    width: 100%;
    padding:8px 0 8px 40px;
}
.text-name2{
    width: 100%;
    padding:8px 0 8px 40px;
}

/* .button1{
    height: 38px;
    width: 75px;
    background-color:#D21A1A;
    color: white;
    cursor: pointer;
} */

/* .button2{
    height: 37px;
    width: 150px;
    background-color: #D21A1A;
    color: white;
    cursor: pointer;
} */
.mapw{
    
    height: 50%;
    
   
}
.text-name, .name{
    border: 1px solid #cccccc;
    outline: none;
    -webkit-transition: all 0.30s ease-in-out;
      -moz-transition: all 0.30s ease-in-out;
      -ms-transition: all 0.30s ease-in-out;
      transition: all 0.30s ease-in-out;
}
.text-name:hover, .name:hover{
    background-color: #fafafa;
}
.text-name:focus, .name:focus{
    -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
      -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
      box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
      border: 1px solid #f5ba1a;
      background: #fafafa;
}
.radio-button{
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
 .gender {
    margin-right: 1em;
}
.gender:before {
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    border-radius: 100%;
    vertical-align: -3px;
    border: 2px solid #cccccc;
    padding: 0.15em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
  }

  .radio-button:hover + .gender:before {
    border-color: #000;
  }
  .radio-button:checked + .gender:before {
    background-color:#000;
    border-color: #000;
  }

  .country{
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: #7b7b7b;
    border: 1px solid #cccccc;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
}
.country::-ms-expand{
    display: none;
}
.country:hover, .country:focus{
    color: #000;
    background: #fafafa;
    border-color: #000;
    outline: none;
}
.arrow {
    position: absolute;
    top: calc(50% - 4px);
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-width: 8px 5px 0 5px;
    border-style: solid;
    border-color: #7b7b7b transparent transparent transparent;
 }
  
.country:hover +.arrow, .country:focus +.arrow{
      border-top-color: #000;
}
.chack{
    margin-right: 1em;
    position: relative;
}
.chack::before{
    content: "";
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.5em;
    vertical-align: -2px;
    border: 2px solid #cccccc;
    padding: 0.12em;
    background-color: transparent;
    background-clip: content-box;
    transition: all 0.2s ease;
}
.chack::after{
    border-right: 2px solid #000;
          border-top: 2px solid #0000;
          content: "";
          height: 20px;
          left: 2px;
          position: absolute;
          top: 7px;
          transform: scaleX(-1) rotate(135deg);
          transform-origin: left top;
          width: 7px;
          display: none;
}
.chack-button{
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.chack-button:hover +.chack::before{
    border-color: #000;
}
.chack-button:checked +.chack::before{
    border-color: #000;
}
.chack-button:checked +.chack::after{
    -moz-animation: check 0.8s ease 0s running;
        -webkit-animation: check 0.8s ease 0s running;
        animation: check 0.8s ease 0s running;
        display: block;
        width: 7px;
        height: 20px;
        border-color: #000;
}
@-webkit-keyframes check {
    0% { height: 0; width: 0; }
    25% { height: 0; width: 7px; }
    50% { height: 20px; width: 7px; }
  }
  
  @keyframes check {
    0% { height: 0; width: 0; }
    25% { height: 0; width: 7px; }
    50% { height: 20px; width: 7px; }
  }
  .button{
    background: #D21A1A;
    height: 35px;
    line-height: 35px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    border-radius: 5px;
    border:solid 1px  black;
    
  }
  .button:hover{
      background-color: green;
  }
  .button:hover{
      background-color: green;
  }



  @media (max-width: 480px) {
      .wrapper{
          width: 100%;
      }
      .input-name{
          margin-bottom: -10px;
      }
      .name{
          width: 100%;
          padding:8px 0 8px 40px;
          margin-bottom: 10px;
      }
      .input-name span{
          padding: 0;
          margin:0;
      }
  }
  .bodyy{
    background: linear-gradient(180deg, rgb(255 255 255 / 80%) 10%, rgb(255 255 255 / 80%) 80% ),url(../../images/masjidImage1.jpg) no-repeat center center;
    background-size: cover;
    height:100%;
    padding-top: 15px;
    padding-bottom: 30px;
    background-attachment: fixed;
    min-height: calc(100vh - 130px)!important;
}
.legend{
    font-size: 13px;
        font-weight: bolder !important;
        text-align: left !important;
        width:auto;
        padding:0 10px;
        border-bottom:none;
        
   
  }
  
.fieldset{
    border: 1px solid #dde2e7 !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }
  .label{
    cursor: text;
    font-family:Helvetica; 
    font-size: 13px;
    color: #374767;
    font-weight: lighter;
  }
.input{
    height: 30px;
    border-radius: 2px;
}
.select{
    height: 30px;
    border-radius: 2px; 
    padding: 5px 0px 5px 0px;
    font-family: Arial;
    font-weight: lighter;
    padding-top:5px;
    padding-bottom:5px;
}
.busi-order {
    padding-left: 93px;
    background-color: white;
}
.busi-tab {
    background-color: white;
    height: auto;
    position: relative;
    padding-bottom: 20px;
    /* width: calc(100% - 18px); */
}
.busi-tabb {
    /* margin-left: 200px; */
    background-color: white;
    /* width: 71.5%; */
    height: auto;
    position: relative;
    padding-bottom: 20px;
}

.busi-tab, .busi-tabb{
    background: #fff;
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
}
.mos-profile {
    background-color: white;
    width: 107%;
    height: auto;
}
/* @media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
    .busi-order {
        padding-left: 0px;
        background-color: white;
    }
    .busi-tab {
        margin-left: 105px;
    }
    .mos-profile {
        margin-right: 100px;
    }
    .busihead {
        padding-left: 95px;
    }
    .busi-tab1 {
        margin-left: 73px;
    }
} */
@media only screen and (min-width:321px) and (max-width:768px){
    .busi-order {
        padding-left: 100px;
    }
    /* .busi-tab {
        margin-left: 80px;
    } */
}
  @media only screen and (min-width:769px) and (max-width:1260px){
    .busi-order {
        padding-left: 400px;
    }
    /* .busi-tab {
        margin-left: 78px;
    } */
}
@media only screen and (max-width: 1440px) {
    .busi-order {
        padding-left: 400px;
    }
    /* .busi-tab {
        margin-left: 70px;
    } */
}
  @media only screen and (min-width: 2560px){
    .mos-profile {
        margin-left: 450px;
        width: 117%;
    }
    .busi-order {
        padding-left: 400px;
    }
    /* .busi-tab {
        margin-left: 580px;
        width: 58.5%;
    } */
}

.newmosque-cont {
    background: white;
    /* width: 72%;
    margin-left: 200px; */
}

.batch-cont {
    background: white;
    width: auto;
    /* margin-left: 200px; */
    /* padding: 13px; */
  }

  /* .defaultimg{
    background: url('../../images/logo1.jpg');
    background-size: cover;
    width: 150px;
    height: 130px;
  } */

  /* @media only screen and (max-width: 486px) {
      .business-head {
        display: flex;
        flex-direction: column;
    }
  }  */


  .admin-tab {
    background-color: white;
    height: auto;
    position: relative;
    padding-bottom: 20px;
    /* width: calc(100% - 18px); */
  }

  /* @media only screen and (max-width: 486px) {
    .admin-head {
      display: flex;
      flex-direction: column;
  }
}  */


.template-images-mosque {
    /* width: '130px'
     height: '150px', 
     cursor: 'pointer', */
     width: 100%;
     height: auto;
     cursor: pointer;
}

.template-image-wrapper-mosque {
    width: 25%;
    padding: 10px 10px 10px 0px;
}

.mosque-temp-width {
    width: 85%;
    /* height: 100%; */
}


@media only screen and (max-width: 486px) {
    .mosque-table {
      width: 100%;
      overflow-x: scroll;
    }
    
    .mosque-table table {
      min-width: 800px;
    }
  }

  .header-image-wrapper {
    overflow-x: scroll;
    width: 100%;
}


.calendar-header {
    background-image: url('../../images/calendar_header.jpg');
    background-size: cover;
}

.main-card{
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
}

.btn-primary{
    background-color: #0bb0b6;
    border-color: #0bb0b6;
}


.btn-primary:hover {
    color: #0bb0b6!important;
    background-color: #fff;
    border-color: #0bb0b6;
}

.btn-secondary {
    background-color: #fff;
    border-color: #0bb0b6;
    color: #0bb0b6;
}

.btn-secondary:hover {
    color: #fff!important;
    background-color: #0bb0b6;
    border-color: #fff;
}

.legend {
    font-size: 18px;
    margin: 10px auto;
    font-weight: 500!important;
    color: #0bb0b6;
  }