.masjidheader {
    /* margin-left: 105px; */
    font-family: 'Alegreya Sans Medium';
    margin-bottom: 0px;
  }
  .masjidheader1 {
    background-color: white;
    box-shadow: 0 3px 6px rgb(48 48 48 / 15%);
    z-index: 10;
    position: relative;
    /* padding-left: 6%; */
  }
  .headad2 {
    height: 60px;
    border-right: none;
    border-top: none;
    padding-left: 65px;
    padding-right: 50px;
    /* background-color: #EEF2F6; */
}

@media only screen and (min-width: 2560px) {
    .headad2 {
        padding-left: 225px;
        padding-right: 200px;
    }
    /* .masjidheader1 {
        padding-left: 12.5%;
    } */
}


.header {
  background-color: #fff;
}

.navbar-links li {
  display: inline-block;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding-left: 0px;
  padding-right: 0px;
}

#home-icon {
  color: #374767;
  padding: 5px;
  font-size: 18px;
  border: solid 1px #ddd;
}

#logout-icon {
  color: #374767;
  padding: 5px;
  font-size: 18px;
  border: solid 1px #ddd;
  cursor: pointer;
}

.nav-item {
  height: 0px;
}

ul.masjidheader > .dropdown {
  padding: 10px 12px !important;
}

ul.masjidheader > .dropdown a p {
  margin-bottom: 0px;
}