@font-face {
    font-family: 'WebFonts';
    src: url(../../../../../assets/fonts/AlegreyaSans-Medium.woff);
  }



.radios2 input[type=radio]:checked+img {
    outline: 58px solid rgba(210, 26, 26, 0.6);
    outline-offset: -58px;
    overflow: hidden;
    position: absolute;
}

.vieworder2-img-active + img {
    outline: 58px solid rgba(210, 26, 26, 0.6);
    outline-offset: -58px;
    overflow: hidden;
    position: absolute;
  }


.radios2 input[type=radio] {
    display: none;
}

input[disabled] {
    cursor: not-allowed;
}

input[type="radio"][disabled] {
    background-color: transparent;
}

input[type="radio"] {
    width: auto;
    line-height: normal;
}

[type=radio] {
    position: absolute !important;
    opacity: 0 !important;
    height: 0 !important;
}



.iqamay1{
    width: 346px;
    height: 478px;
    top: 300px;
    left: 18px;
    position: absolute;
    
}

.timey1{
    width: 346px;
    height: 58px;
    position: absolute;
    top: 258px;
    left: 44px;
    
}

.temy1{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 41px;
    padding: 4px;
}

.temy2{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 152px;
    padding: 4px;
}

.temy3{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 263px;
    padding: 4px;
}

.temy4{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 374px;
    padding: 4px;
}

.temy5{
    /* width: 115px;
    height: 210px; */
    width: 1.22in;
    height: 2.187in;
    cursor: pointer;
    position: absolute;
    top: 44px;
    left: 485px;
    padding: 4px;
}

.temy6{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;  
    left: 394px;
    top: 253px;
    padding: 5px;
}

.temy7{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 394px;
    top: 363px;
    padding: 5px;
}

.temy8{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 394px;
    top: 473px;
    padding: 5px;
}

.temy9{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;  
    left: 394px;
    top: 583px;
    padding: 5px;
}

.temy10{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;  
    left: 394px;
    top: 693px;
    padding: 5px;
}

/* .iqamay1-table tr th:first-child,
.iqamay1-table tr td:first-child {
    background-color: #f5f5dc;
}

.iqamay1-table tr th:nth-child(3),
.iqamay1-table tr td:nth-child(3) {
    background-color: #f5f5dc;
}

.iqamay1-table tr th:nth-child(4),
.iqamay1-table tr td:nth-child(4) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 80px;
}

.iqamay1-table tr th:nth-child(5),
.iqamay1-table tr td:nth-child(5) {
    background-color: #f5f5dc;
}

.iqamay1-table tr th:nth-child(7),
.iqamay1-table tr td:nth-child(7) {
    background-color: #f5f5dc;
}

.iqamay1-table tr th:nth-child(2),
.iqamay1-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqamay1-table tr th:nth-child(6),
.iqamay1-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */

.head-size{
    font-size: 6px;
    background: #f5f5dc;
}
.iqamay1-table th,
.iqamay1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    /* padding: 7.9px;
    padding-left: 0px; */
}



/* template6 css */
.iqamag1{
    width: 346px;
    height: 478px;
    top: 80px;
    left: 18px;
    position: absolute;
}

.timeg1{
    width: 346px;
    height: 55px;
    position: absolute;
    top: 43px;
    left: 44px;
}

.temg1{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 40px;
    padding: 6px;
}

.temg2{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 146px;
    right: 40px;
    padding: 6px;
}

.temg3{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 256px;
    right: 40px;
    padding: 6px;
}

.temg4{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 365px;
    right: 40px;
    padding: 6px;
}

.temg5{
    /* width: 210px;
    height: 117px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    top: 474px;
    right: 40px;
    padding: 6px;
}

.temg6{
    /* width: 110px;
    height: 210px; */
    width: 1.04in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 45px;
    padding: 3px;
}

.temg7{
    /* width: 115px;
    height: 210px; */
    width: 1.16in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 148px;
    padding:3px;
}

.temg8{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 262px;
    padding: 3px;
}

.temg9{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    right: 377px;
    padding: 3px;
}

.temg10{
    /* width: 115px;
    height: 210px; */
    width: 1.17in;
    height: 2.197in;
    cursor: pointer;
    position: absolute;
    top: 594px;
    left: 42px;
    padding: 3px;
}

/* .iqamag1-table tr th:first-child,
.iqamag1-table tr td:first-child {
    background-color: #f5f5dc;
}

.iqamag1-table tr th:nth-child(3),
.iqamag1-table tr td:nth-child(3) {
    background-color: #f5f5dc;
}

.iqamag1-table tr th:nth-child(4),
.iqamag1-table tr td:nth-child(4) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 80px;
}

.iqamag1-table tr th:nth-child(5),
.iqamag1-table tr td:nth-child(5) {
    background-color: #f5f5dc;
}

.iqamag1-table tr th:nth-child(7),
.iqamag1-table tr td:nth-child(7) {
    background-color: #f5f5dc;
}

.iqamag1-table tr th:nth-child(2),
.iqamag1-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqamag1-table tr th:nth-child(6),
.iqamag1-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqamag1-table th,
.iqamag1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    padding-left: 0px;
}

.head-size{
    font-size: 6px;
    
}

/* Template7 css */
.iqamau2{
    width: 346px;
    height: 478px;
    top: 300px;
    left: 233px;
    position: absolute;
}

.timeu2{
    width: 346px;
    height: 58px;
    position: absolute;
    top: 258px;
    left: 253px;
}
.temu6{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 253px;
    padding: 5px;
}

.temu7{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 363px;
    padding: 5px;
}

.temu8{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 473px;
    padding: 5px;
}

.temu9{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    /* top: 5px; */
    left: 40px;
    top: 583px;
    padding: 5px;
}
.temu10{
    /* width: 210px;
    height: 115px; */
    width: 2.187in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: 693px;
    padding: 5px;
}

/* .iqamau2-table tr th:first-child,
.iqamau2-table tr td:first-child {
    background-color: #f5f5dc;
}

.iqamau2-table tr th:nth-child(3),
.iqamau2-table tr td:nth-child(3) {
    background-color: #f5f5dc;
}

.iqamau2-table tr th:nth-child(4),
.iqamau2-table tr td:nth-child(4) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 80px;
}

.iqamau2-table tr th:nth-child(5),
.iqamau2-table tr td:nth-child(5) {
    background-color: #f5f5dc;
}

.iqamau2-table tr th:nth-child(7),
.iqamau2-table tr td:nth-child(7) {
    background-color: #f5f5dc;
}

.iqamau2-table tr th:nth-child(2),
.iqamau2-table tr td:nth-child(2) {
    background-color: #fce5d7;
}

.iqamau2-table tr th:nth-child(6),
.iqamau2-table tr td:nth-child(6) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqamau2-table th,
.iqamau2-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    padding-left: 0px;
}

.head-size{
    font-size: 6px;
    
}

/* template8 css */
.iqamaz1{
    width: 346px;
    height: 478px;
    top: 80px;
    left: 233px;
    position: absolute;
}

.timez1{
    width: 346px;
    height: 55px;
    position: absolute;
    top: 43px;
    left: 259px;
}

.temz1{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 36px;
    padding: 6px;
}

.temz2{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 146px;
    padding: 6px;
}

.temz3{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 256px;
    padding: 6px;
}

.temz4{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 365px;
    padding: 6px;
}

.temz5{
    /* width: 210px;
    height: 117px; */
    width: 2.197in;
    height: 1.17in;
    cursor: pointer;
    position: absolute;
    left: 39px;
    top: 474px;
    padding: 6px;
}



/* .iqamaz1-table tr th:first-child,
.iqamaz1-table tr td:first-child {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 30px;
}

.iqamaz1-table tr th:nth-child(2),
.iqamaz1-table tr td:nth-child(2) {
    background-color:#f5f5dc;
    min-width: 80px !important;
    width: 25px;
}


.iqamaz1-table tr th:nth-child(4),
.iqamaz1-table tr td:nth-child(4) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 30px;
}

.iqamaz1-table tr th:nth-child(5),
.iqamaz1-table tr td:nth-child(5) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 70px;
}

.iqamaz1-table tr th:nth-child(6),
.iqamaz1-table tr td:nth-child(6) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 43px;
}

.iqamaz1-table tr th:nth-child(8),
.iqamaz1-table tr td:nth-child(8) {
    background-color: #f5f5dc;
    min-width: 80px !important;
    width: 43px;
}

.iqamaz1-table tr th:nth-child(3),
.iqamaz1-table tr td:nth-child(3) {
    background-color:  #FFE6EE;
    min-width: 80px !important;
    width: 43px;
}

.iqamaz1-table tr th:nth-child(7),
.iqamaz1-table tr td:nth-child(7) {
    background-color: #e4f2db;
    min-width: 52px !important;
    width: 52px;
} */

.title-second {
    color: red;
}

/* table th,
table td {
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
} */
.iqamaz1-table th,
.iqamaz1-table td{
    line-height: 0px;
    border: 1px solid #dddddd; 
    padding: 7.9px;
    /* padding-left: 0px; */
    font-family: Arial;
    font-weight: 700;
}

.head-size{
    font-size: 6px;
    
}


.danger {
    background-color: #E5343D;
    border-color: #BF2D35;
    color: #fff;
    border-radius: 2px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.8;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    margin-top: 28px;
    /* margin-left: 10px; */
  }

  @media only screen and (max-width: 1440px) {
    .vieworder-scroll {
        overflow-x: scroll;
    }
}

@media only screen and (max-width: 1024px) {
  .vieworder-flex {
      display: flex;
      flex-direction: column;
      width: 100%;
  }
}


/* Virtual template */
.trow {
    width: 100%;
    display: flex;
    text-align: center;
}

.tcol {
    display:inline-block;
    width: 100%;
    border: 1px solid #b4b3b3;
    /* background: #f5f5dc; */
    background: white;
    padding-top: 3px;
}

.fajriqama-col {
    background: #FFE6EE;
    min-width: 60px !important;
    width: 60px;
}

.maghribiqama-col {
    background: #e4f2db;
    min-width: 85px !important;
    width: 85px;
}

.dhuhr-col {
    min-width: 116px !important;
    width: 116px;
}

.asr-col {
    min-width: 60px !important;
    width: 60px;
}

.isha-col {
    /* min-width: 78.18px !important;
    width: 78.18px; */
    min-width: 76px !important;
    width: 76px;
}

.date-col {
    min-width: 40px !important;
    width: 40px;
}

.day-col {
    min-width: 40px !important;
    width: 40px;
}

.sunrise-col {
    min-width: 40px !important;
    width: 40px;
}

.template-hide {
    height: 1px;
    overflow: hidden;
    opacity: 0;
}
  @media print {
    .template-hide {
      display: block;
      opacity: 1;
      overflow: visible;
      height: auto;
    }
  }

  .fa-col {
    background: #FFE6EE;
    min-width: 41px !important;
    width: 41px;
}

.mag-col {
    background: #e4f2db;
    min-width: 50px !important;
    width: 50px;
}

.dhu-col {
    min-width: 70px !important;
    width: 70px;
}

.as-col {
    min-width: 45px !important;
    width: 45px;
}

.ish-col {
    min-width: 45px !important;
    width: 45px;
}

.dat-col {
    min-width: 30px !important;
    width: 30px;
}