.foot{
    /* background-color: #EEF2F6; */
    padding: 60px;
    padding-left: 150px;
    /* padding-bottom: 10px; */
    /* height: 250px; */
    width: auto;
    background-color: #0BB0B6;
    border-color: #e1e1e1;
    border-style: solid;
    border-width: 0;
    zoom: 1;
    color: #3d3d3d;
    line-height: 30px;
    /* position: absolute; */
    bottom: 0px;

}

.foot h5 {
    position: relative;
    line-height: 1;
    padding-bottom: 15px;
    margin: 0px 0px 8px;
    background: none;
    color: #fff;
}
.foot h5::after {
    position: absolute;
    width: 40px;
    height: 2px;
    z-index: 10;
    background: #FFBC00;
    content: '';
    bottom: 0px;
    left: 0px;
}

.copy{
    background-color: #333;
    padding: 20px 20px 20px 20px;
    color: #777;
    /* padding-left: 150px; */
    /* font-size: 13px; */
    line-height: 24px;
    margin: 0;
    min-height: 24px;
    
    position: relative;
}
.sup{
    color:#fff;
    font-size: 15px;
    font-family: Arial,sans-serif;
    margin-left: 0px;
    
}
.icns{
    padding: 10px;
    
}
.abt{
    font-family: Arial,sans-serif;
    font-size: 22px;
    /* line-height: 30px; */
    position: relative;
    font-weight: normal;
    line-height: 1px;
    margin-left: 4px;
    background-color:  #eef2f6;
    /* border-bottom: 2px solid #e3e3e3; */

}
.admnfoot{
    background-color: #EEF2F6;
    padding: 5px;
    
}
.txt{
    /* text-align: center; */
    font-size: 14px;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
}



#site-footer{
    font-size: 15px;
    line-height: 24px;
    position: relative;
    margin-top: auto!important;
    display: block;
}

#site-footer .top-box {
    background-color: #eef2f6;
    padding: 40px 0 40px;
    
}

.container {
    margin-right: auto;
    margin-left: auto;
    
}

/* @media (min-width: 1200px){
.container {
    width: 1170px;
}
} */




#site-footer .title, #site-footer .widget-title {
    /* border-bottom: 2px solid #e3e3e3; */
    font-size: 22px;
    font-weight: normal;
    line-height: 30px;
    margin: 0 0 15px;
    padding: 0 0 14px;
    position: relative;
}
.foot-img {
    width: 430px;
    height: 20px;
}
.wel-img {
    width: 200px;
}
.back-color-foot {
    background-color:  #eef2f6;
}
@media only screen and (min-width: 280px) and (max-width: 320px) {
    .foot {
        padding-left: 0px;
    }
    .termser {
        margin-top: 15px;
    }
    .abt {
        margin-left: 8px;
    }
    /* .copy {
        padding-left: 30px;
        padding-bottom: 60px;
    } */
    .back-color-foot {
        background-color:  #eef2f6;
    }
}

@media only screen and (min-width:321px) and (max-width:767px) {
    .foot {
        padding: 20px;
    }
    .termser {
        margin-top: 15px;
    }
    .abt {
        margin-left: 8px;
    }
    /* .copy {
        margin-top: 100%;
        padding-left: 30px;
        padding-bottom: 60px;
    } */
    .back-color-foot {
        background-color: none;
    }
}

@media only screen and (width: 768px) {
    .foot {
        padding-left: 3%;
    }
    /* .copy {
        margin-top: 15%;
    } */
    .btrees-foot {
        margin-left: -100%;
    }
  }
/* .contact {
    margin-top: 0%;
    margin-right: none;
} */
/* @media only screen and (min-width: 768px) {
    .foot {
        padding-left: 1%;
    }
    .contact {
        margin-top: 0%;
        margin-right: 40%;
    }
} */

@media only screen and (min-width:769px) and (max-width:1260px) {
    /* .abt {
        margin-left: -20px;
    }
    .wel-img {
       margin-right: 10%;
    }
    .sup {
        margin-right: 20%;
    } */
    /* .copy {
        margin-top: 10%;
    } */
}
@media only screen and (min-width: 2560px) {
    .foot {
        margin-left: 10%;
    }
}
/* @media only screen and (min-resolution: 117dpi) and (max-resolution: 119dpi), only screen and (min-resolution: 131dpi) and (max-resolution: 133dpi), only screen and (min-resolution: 145dpi) and (max-resolution: 154dpi), only screen and (min-resolution: 162dpi) and (max-resolution: 164dpi), only screen and (min-resolution: 169dpi) {
    .foot {
     height: auto;
     width: auto;
    }
    .foot-img {
        width: 430px;
        height: 20px;
    }
    .wel-img {
        width: 200px;
    }
    .termser {
        margin-top: 15px;
    }
} */